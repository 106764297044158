<!-- 这个相当于是那个任务box在这里 -->
<template>
  
        <div class="add_task_area" id="add_task" >
          <div class="input_task_area" id="input_task_area">
              
            <div class="task_container">
            <input class="input_text" v-model="taskcontent"  type="text"  placeholder="请输入你的任务内容" >
            </div>


  



            <div class="task_property_area">
              <div class="calendar_project_area">
                <!-- <div class="calendar_area" id="calendar_area">
                  <button id="calendar" class="clock_button">
                <svg t="1598153562536" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"  p-id="3498" width="20" height="20"><path d="M106.666667 810.666667V298.666667h810.666666v512c0 46.933333-38.4 85.333333-85.333333 85.333333H192c-46.933333 0-85.333333-38.4-85.333333-85.333333z" fill="#CFD8DC" p-id="3499"></path><path d="M917.333333 213.333333v128H106.666667v-128c0-46.933333 38.4-85.333333 85.333333-85.333333h640c46.933333 0 85.333333 38.4 85.333333 85.333333z" fill="#F44336" p-id="3500"></path><path d="M704 213.333333m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" fill="#B71C1C" p-id="3501"></path><path d="M320 213.333333m-64 0a64 64 0 1 0 128 0 64 64 0 1 0-128 0Z" fill="#B71C1C" p-id="3502"></path><path d="M704 64c-23.466667 0-42.666667 19.2-42.666667 42.666667v106.666666c0 23.466667 19.2 42.666667 42.666667 42.666667s42.666667-19.2 42.666667-42.666667V106.666667c0-23.466667-19.2-42.666667-42.666667-42.666667zM320 64c-23.466667 0-42.666667 19.2-42.666667 42.666667v106.666666c0 23.466667 19.2 42.666667 42.666667 42.666667s42.666667-19.2 42.666667-42.666667V106.666667c0-23.466667-19.2-42.666667-42.666667-42.666667z" fill="#B0BEC5" p-id="3503"></path><path d="M277.333333 426.666667h85.333334v85.333333h-85.333334zM405.333333 426.666667h85.333334v85.333333h-85.333334zM533.333333 426.666667h85.333334v85.333333h-85.333334zM661.333333 426.666667h85.333334v85.333333h-85.333334zM277.333333 554.666667h85.333334v85.333333h-85.333334zM405.333333 554.666667h85.333334v85.333333h-85.333334zM533.333333 554.666667h85.333334v85.333333h-85.333334zM661.333333 554.666667h85.333334v85.333333h-85.333334zM277.333333 682.666667h85.333334v85.333333h-85.333334zM405.333333 682.666667h85.333334v85.333333h-85.333334zM533.333333 682.666667h85.333334v85.333333h-85.333334zM661.333333 682.666667h85.333334v85.333333h-85.333334z" fill="#90A4AE" p-id="3504"></path></svg>

                  </button>
                </div> -->

                <div id="goal_list1" class="goal_list">
                </div>
            </div>

              <div id="tags_review_area1" class="tags_review_area" @click="clicktag">
                <div id="tasktags_area1" >
                  <button id="tags_button1" class="tags_button" @click="clicktag">
                   <svg t="1602774165074" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2453" width="15" height="15"><path d="M344.064 194.56c-72.704 0-132.096 59.392-132.096 132.096s59.392 132.096 132.096 132.096c72.704 0 132.096-59.392 132.096-132.096S416.768 194.56 344.064 194.56z m0 185.344c-29.696 0-53.248-23.552-53.248-53.248 0-29.696 23.552-53.248 53.248-53.248 29.696 0 53.248 23.552 53.248 53.248 0 29.696-24.576 53.248-53.248 53.248z m656.384 143.36L512 32.768C496.64 17.408 476.16 9.216 454.656 9.216H79.872C35.84 9.216 0 45.056 0 89.088v345.088c0 21.504 8.192 40.96 23.552 56.32l503.808 501.76c15.36 15.36 35.84 23.552 57.344 23.552 21.504 0 41.984-8.192 56.32-23.552L1000.448 634.88c31.744-30.72 31.744-80.896 0-111.616zM583.68 934.912l-503.808-501.76V89.088h374.784l488.448 490.496L583.68 934.912z" p-id="2454" fill="#d81e06"></path></svg>
                  </button>
                </div>
              </div>
            </div>
        
        
            <div class="add_task_button_area" id="add_task_button_area">
                <a-button class="addtask" @click="addtaskinproject(goal.Goalcode,project.Name,project)">添加任务</a-button> 
                <div  v-show="showtagselect"  class="add_tasktags_area" id="add_tasktags_area">
                 <a-select  
                    v-model:value="value"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="选择任务属性..."
                    :max-tag-count="maxTagCount"
                 
                    :options="tasktag_options"
                    @change="handleChange"
                    ></a-select>
                </div>
            </div>

            <SmartIndex
      :difficultyValue="difficultyValue"
      :moodValue="moodValue"
      @update:difficultyValue="updateDifficultyValue"
      @update:moodValue="updateMoodValue"
      v-if="isVipUser"
    />
          </div>
         
        </div>
   
</template>



<script>

import configure_options from '../configure'
import { defineComponent,ref } from 'vue';
import emitter from "../eventbus.js"; 
import SmartIndex from './smartindex.vue'; 
import { computed } from 'vue';
import { useStore } from 'vuex';
const axios = require('axios').default;
export default defineComponent( {
  name: 'taskbox',
  props: {
    msg: String,
    goal: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  mounted(){
  // this.options =this.gettasktags()
  // this.options =this.getreviewalgo()
  this.tasktag_options =this.gettasktags()
},
components:{
  SmartIndex
},
methods:{
  onPanelChange(value, mode) {
      console.log(value, mode);
      
    },
},
setup(props,context) {
  const value1 = ref(500);
    const handleChange = value => {
      console.log(`selected ${value}`);
      context.emit("testchild")
    };
    const store = useStore();
  const isVipUser = computed(() => {
  return store.getters.userLevel === 'vip';
});
    const difficultyValue = ref(2);  // 初始化难度值
    const moodValue = ref(0);  // 初始化情绪指数
    function updateDifficultyValue(newVal) {
  difficultyValue.value = newVal;
}

// 当子组件更新情绪指数时触发的函数
function updateMoodValue(newVal) {
  moodValue.value = newVal;
}
   var  options =  [{
                  id:0,
                  value:"容易的",  
                },{
                  id:1,
                  value:"麻烦事"
                },{
                  id:2,
                  value:"体力活"
                },
                {
                  id:3,
                  value:"3 minutes"
                },
                {
                  id:4,
                  value:"10 minutes"
                },
                {
                  id:5,
                  value:"重要的"
                },
                {
                  id:6,
                  value:"不舒服的"
                },
                
                
                ]

    function clicktag(){
      // alert("检测是否被调用")
    this.showtagselect = true;
  }





function gettasktags(){
    //尝试从服务器获取对应的部分 
    var final_options = []

var env =  configure_options["env"]
           
  console.log(configure_options["app_setting"][env])
           var serverhost =configure_options["app_setting"][env]["serverhost"]
    var _this = this
       axios.get(serverhost+"/v1/gettags").then(
        response=>{
          var data = response.data
              console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
         console.log(data);
         _this.items = data.tags
         // console.log(status);
         // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
        
         var taglocaldata =['重要的', '害怕的', '原则', '原子', '需要深度搜索', 
         '困难的', '容易的', '急迫的', '挑战的', '不舒服的', '麻烦事', 
         '关键问题', '10分钟', '30分钟', '3分钟', '人生伟大时刻']
     for(var i=0;i<taglocaldata.length;i++){
        final_options.push({id:i,value:taglocaldata[i]})
      }

      for(var j=0;j<data.tags.length;j++){
        final_options.push({id:j,value:data.tags[j].Name})
      }
      _this.tasktag_options =final_options

      return final_options

   
        }
      ).catch(error=>{console.log(error)})
//  []

  }



  function  addtaskinproject(goalcode,projectName,project){
    // alert(`父亲任务是${parenttask.task}`)
    // alert(this.$store.state.number)
    // alert(this.taskcontent)
    // alert(this.plantime)
    if(this.taskcontent=="unspecified"||this.taskcontent==""){
      alert("必须指定任务内容")
      return
    }


    console.log("project is below")
    console.log(project)
    // alert(goalcode)
    // alert(project)
    // alert(this.taskcontent)
    console.log(goalcode,projectName)
    this.showtagselect = false;
   
   console.log("🧃🧃🧃🧃")
   console.log(moodValue,difficultyValue)
   //促进一个状态的改变
    // var task =  parenttask
    // const  scheduleids = [task.ID]
      var tagscovert = {
      "importanttag": "重要的",
      'feartag': "害怕的",
      'principletag': "原则",
      'atomtag': "原子",
      'dfstag': "需要深度搜索",
      'hardtag': "困难的",
      'easytag': "容易的",
      'urgenttag': "急迫的",
      'challengetag': "挑战的",
      'uncomfortabletag': "不舒服的",
      'troublesometag': "麻烦事",
      'keyproblemtag': "关键问题",
      'tenminutestasktag': "10分钟",
      'thirtyminutestasktag': "30分钟",
      'threeminutestasktag': "3分钟",
      'lifetag': "人生伟大时刻",
      // 'recordtag': "创造新的纪录",
    }
   

  var tasktags_dict = {
      'importanttag': 'no',
      'feartag': 'no',
      'principletag': 'no',
      'atomtag': 'no',
      'dfstag': 'no',
      'hardtag': 'no',
      'easytag': 'no',
      'urgenttag': 'no',
      'challengetag': 'no',
      'uncomfortabletag': 'no',
      'troublesometag': 'no',
      'keyproblemtag': 'no',
      'tenminutestasktag': 'no',
      'thirtyminutestasktag': 'no',
      'threeminutestasktag': 'no',
      'lifetag': 'no',
      'recordtag':'no'
    }


   var task = {
          'starttime': 'unspecified',
          'deadline': 'unspecified',
          'endtime': 'unspecified',
          'ifdissect': 'no',
          'note': 'unspecified',
          'parentid': "unspecified",
          'timedevotedto_a_task': "unspecified",
          'goal': 'unspecified',
          'parentproject': 'unspecified',
          'inboxlist': 'empty',
          "client":"gtdcli",
          "goalcode":goalcode,
          'inbox': this.taskcontent,
          "moodlevel":moodValue.value,
          "difficultylevel":difficultyValue.value,
          'finishtime': 'unspecified',
          'reviewalgolight': 'no',
          'reviewalgo': "unspecified",
          'place': 'unspecified',
          'input-time': 'Fri Aug 21 22:38:23 2020',
          'project': projectName,
          'projectcode':project.Projectcode,
          'taskstatus': 'unfinished',
          'plantime': 'unspecified',
          'taglight': 'yes',
          'tasktags': tasktags_dict,
          // 'client':"dreamforlifeweb",
          'tasktagsorigin': 'e'
        };

  // 采用循环来提取对应的tag部分
  var task_tags = []
  for(var tag in tagscovert){
    if(this.value.includes(tagscovert[tag])){
      // task_tags.push(tag)
      tasktags_dict[tag]="yes"
    }
  }



   // 直接进行循环测试
   for(var selectedtag in this.value){
    
    tasktags_dict[this.value[selectedtag]]="yes"
  }
  console.log("选中的任务属性在这里")
  console.log(task_tags)

   console.log(`观测这里的project ${projectName}，projectcode${'sss'}`)

     var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

  //  const postbody = {"parentid":parenttask.ID,"inbox":"测试任务"}
   axios.post(serverhost + '/v1/createtask', task)
    .then(response => {
      
        console.log(response.data)
        var task = response.data.task
        var tags =   JSON.parse(task.tasktags)
        var tagsfromfilter =   Object.keys(tags).filter((key) => tags[key] === "yes");
        tagsfromfilter.push("tasktext")
        task.tagsarray = tagsfromfilter
        //可以将状态传入到父亲组件
         document.getElementById("addtaskinprojectzone").style.display="none"
       document.getElementById("goaltreedatapart").style.opacity=1
       document.getElementById("goaltreedatapart").style.pointerEvents="auto"
       document.getElementById("top").style.opacity=1
      emitter.emit("createtaskINproject",task)
    })
    .catch(error=>{console.log(error)})
      return 
  }


    return {
      isVipUser,
      moodValue,
      difficultyValue,
      updateDifficultyValue,
      updateMoodValue,
      showtagselect:ref(false),
      handleChange,
      addtaskinproject,
      clicktag,
      gettasktags,
      tasktag_options:ref([]),
      value1,
      options,
      value: ref([]),
      plantime:ref(null),
      // editcontent:props.parenttask.task,
      taskcontent:ref(""),
      maxTagCount:ref(2),
      taskchecked: ref(false),
    };
  },




})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input.input_text,.edit_text{
  border: none;
  width:100%;

  outline:none;

}



#input_task_area{
    /* max-width: 30em; */
    /* width: 700px; */
    width: 80%;
    /* height: 4em; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    margin-top: 0.2em;
    border: solid 0.1em #ccc;
    border-radius: 0.5em;

}
  



.tags_button{
  background: none;
  border:none
}

.add_task_button_area {  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr; */
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "addtask tagzone tagzone tagzone";
}

.addtask { 
  grid-area: addtask;
  border-radius:10px;
  color:white;
  font-weight: 900;
  border:solid 1px;
  background: #1a73e8;
  max-width:100px;
  &:hover{
    background: #4479be;
  }
}

.add_tasktags_area { grid-area: tagzone; }
</style>

<template>
    <div >
        <niubilitybadge height="100px" width="100px" />
  
    </div>
  </template>
  
  <script>



  import niubilitybadge from  "../svg/niubilitybadge.vue"
//   import closebutton from "../svg/close.vue"
  export default {
    data() {
      return {
        messages: [],
        newMessage: '',
        user: 'User',
        showpridetasks: true, 
        tasktypedata: 'defaultTaskType',
        showtoolboxdata: true,
        showcheckboxdata: true,
        treeData: {}

      };
    },
    components: {
    
      niubilitybadge,
     
    },
    methods: {
    
    }
  };
  </script>
  
  <style scoped>
  .chat-container {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .messages {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  .message {
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 4px;
    max-width: 90%;
    word-wrap: break-word;
  }
  .user-message {
    background-color: #b1eec5;
    align-self: flex-start;
  }
  .server-message {
    background-color: #edeff5;
    align-self: flex-end;
  }
  .input-container {
    display: flex;
  }
  .message-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .send-button {
    margin-left: 8px;
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  .send-button:hover {
    background-color: #0056b3;
  }
  .image-message {
    max-width: 100%;
    border-radius: 4px;
  }
  </style>
  
<template>
    <div class="three-container" ref="threeContainer"></div>
  </template>
  
  <script>
  import * as THREE from "three";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
  
  export default {
    name: "Apple3D",
    mounted() {
      this.initThree();
    },
    methods: {
      initThree() {
        const container = this.$refs.threeContainer;
  
        // 初始化场景、相机和渲染器
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
          75,
          container.clientWidth / container.clientHeight,
          0.1,
          1000
        );
        camera.position.set(0, 2, 5); // 调整相机位置，显示更好的视角
  
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(container.clientWidth, container.clientHeight);
        renderer.setClearColor(0x000000, 0); // 设置透明背景
        renderer.shadowMap.enabled = true; // 启用阴影
        container.appendChild(renderer.domElement);
  
        // 添加光源
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // 环境光
        scene.add(ambientLight);
  
        const spotLight = new THREE.SpotLight(0xffffff, 1); // 聚光灯
        spotLight.position.set(5, 10, 5);
        spotLight.castShadow = true; // 启用阴影
        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;
        scene.add(spotLight);
  
        // 创建地面
        const groundGeometry = new THREE.PlaneGeometry(10, 10);
        const groundMaterial = new THREE.ShadowMaterial({ opacity: 0.3 });
        const ground = new THREE.Mesh(groundGeometry, groundMaterial);
        ground.receiveShadow = true; // 接收阴影
        ground.rotation.x = -Math.PI / 2; // 水平放置
        ground.position.y = -1.5;
        scene.add(ground);
  
        // 使用 LatheGeometry 创建更真实的苹果形状
        const appleCurve = [];
        appleCurve.push(new THREE.Vector2(0, 0)); // 底部点
        appleCurve.push(new THREE.Vector2(0.8, 0.2)); // 渐变到较宽
        appleCurve.push(new THREE.Vector2(1.5, 1.5)); // 中间最宽
        appleCurve.push(new THREE.Vector2(1.3, 2.5)); // 向顶部逐渐变窄
        appleCurve.push(new THREE.Vector2(0.7, 3)); // 接近顶部的小半径
        appleCurve.push(new THREE.Vector2(0, 3.2)); // 顶部点
        const appleGeometry = new THREE.LatheGeometry(appleCurve, 32);
  
        // 添加真实纹理的苹果材质
        const textureLoader = new THREE.TextureLoader();
        const appleTexture = textureLoader.load("./apple.webp"); // 替换为你的纹理路径
        const appleMaterial = new THREE.MeshPhongMaterial({
          map: appleTexture,
          shininess: 100,
        });
        const apple = new THREE.Mesh(appleGeometry, appleMaterial);
        apple.castShadow = true; // 苹果投射阴影
        scene.add(apple);
  
        // 创建苹果茎
        const stemGeometry = new THREE.CylinderGeometry(0.05, 0.05, 0.5, 32);
        const stemMaterial = new THREE.MeshBasicMaterial({ color: 0x8b4513 });
        const stem = new THREE.Mesh(stemGeometry, stemMaterial);
        stem.position.set(0, 3.4, 0); // 茎的位置在苹果顶部
        stem.castShadow = true;
        scene.add(stem);
  
        // 创建叶子
        const leafGeometry = new THREE.PlaneGeometry(0.5, 0.3);
        const leafMaterial = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          side: THREE.DoubleSide,
        });
        const leaf = new THREE.Mesh(leafGeometry, leafMaterial);
        leaf.position.set(0.3, 3.5, 0.2); // 叶子位置
        leaf.rotation.set(-Math.PI / 4, 0, Math.PI / 4); // 调整叶子角度
        leaf.castShadow = true;
        scene.add(leaf);
  
        // 添加鼠标控制（OrbitControls）
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true; // 惯性效果
        controls.dampingFactor = 0.05;
  
        // 动画循环
        const animate = () => {
          requestAnimationFrame(animate);
          apple.rotation.y += 0.01; // 旋转苹果
          stem.rotation.y += 0.01; // 同步旋转茎
          leaf.rotation.y += 0.01; // 同步旋转叶子
          controls.update();
          renderer.render(scene, camera);
        };
        animate();
  
        // 自动调整窗口大小
        window.addEventListener("resize", () => {
          camera.aspect = container.clientWidth / container.clientHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(container.clientWidth, container.clientHeight);
        });
      },
    },
  };
  </script>
  
  <style>
  .three-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent; /* 确保背景是透明的 */
  }
  </style>
  
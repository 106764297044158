<template>
<div class="memeberzone" id="memeberzone">
  <a href="/taskmanager" class="backbutton"  >{{ symbol}}任务面板</a>
  <div class="memberoptions"  v-if="!this.showuserinfo">
  
    <div class="money-list buyoptionszone">
      
      <div data-v-c226bfef="" class="title"><div>选择会员购物包</div>
      <div class="giftcodebutton" @click="this.showgiftinput=!this.showgiftinput">礼品码兑换🎁</div>
    </div>
      <div v-if="this.showgiftinput" >
        <input placeholder="请输入你的兑换码" v-model="this.giftcode"/>
        <button @click="this.getgift()">兑换</button>
      </div>
     
      <div data-v-c226bfef="" class="titleCoupon titleWidth">
        <!-- <img data-v-c226bfef=""
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAsCAMAAAAtr3oOAAABRFBMVEUAAAD/nQD/nAD/nQD/mgD/nQD/nQD/nQD/4cP/nQD/nQD/lwD/nQD/nQD/nQD/ngD/nQD/mgD/nQD/2rb/y5v/qCv/1av/xpX/mgD/nQD/nQD/nQD/ngD/ngD/mwD/nwD/3bz/37z/3sH/oAD/4cP/4cT/nQD/4MD/ngD/4ML/38L/ngD/nQD/y6n/nQD/ngD/nwD/28L/nAD/y6H/4ML/2bP/nQD/nQD/piP/38L/qCf/wYf/oQ//38L/yqH/ngD/ngD/0Lj/oAD/29v/v4D/nQD/ngL/mwD/mAD/////qS3/oxD//Pf/8Nj/5Lj/w2T/t0X/rSn/1pT/xZb/wYj/4sX/xJP/wIX/sTX/wVz/uUn/qSD//vz/3b3/15b/04z/xIz/vVT/qzH/nwn/7ND/5bn/4bH/z6L/yZb/wl//rir/phhy+TwwAAAARXRSTlMA/SztBfsf/P6s+Qz2797HvBoI/v7+/f396OLQurRFOzYxKRHr6MCnoqGFg3FgWFMoFRT79PTy6uXa2cfAuKmQX0EzIxCnpFRJAAABuklEQVRIx+3S11bCQBCA4Y0Sg0SkCCIiimLvvXedbBC7QZodu+9/78ImYZbDCTwA3yXnZxiSIS1NGUksNR8P742ubk0vNTt6LONmxqZHmsqnenp7Ht3uTGZ0arhxnQgZhvHy/VX+TmYi0aA+3ckaTDabNV4iod61Cf4LLlNtPncXuTPdM5HQyv7c0QmpL7iwfSYIh8Nvb2ebu53c5Dyu1Tj00Vp9HvYZcBL4k8TkIjOgSFodkiJb4IBYkjGQwKIJwOZViamj3841oBhUdQXsvNvOaSmNlWg170DTZT6b5t4vBNc630cBj50HxhU+XtML54JUgecSyLEAsSwPDfAeaPEG+S1S4PX44DLBJkHh6+iYVfuDBHORNm9lPgUNA94rx6wQDYDM6pvPj0ssRzU2xscTPD7Ock1/TWEPqbxezuOklhrjy+eukUIOKqKqONtF5q1HIwJuliW4X9gAXusis/ce4kVm/R6z/nt+uq16ei5S88r8M2rtEdT5qz/oCNCJKeb0dP4Ky6cpOjF8kU67M/jEqjloInTvSfRGPZIzGb/ZoXVopGuQoN4XbXfgjfpYjQQX25wsBkmLo3+i/tc49bCn6gAAAABJRU5ErkJggg=="
          alt="优惠券">
          <label data-v-c226bfef="">Use coupons</label> -->
        
        </div>
        
          <!---->
      <div data-v-c226bfef="" class="list">
        <!-- <div data-v-c226bfef="" class="list-card">
          <div data-v-859f54fe="" data-v-c226bfef="" class="card svip active">
            <div data-v-859f54fe="" class="background" style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="background def_border"
              style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="tips long-class"><img data-v-859f54fe=""
                src="https://cdn-v.caiyunai.com/p/activity/promotion_202206_2/矩形@2x.png" alt=""><span
                data-v-859f54fe="">special price</span></div>
            <div data-v-859f54fe="" class="info infoTypes">
              <div data-v-859f54fe="" class="top">monthly renewal</div>
              <div data-v-859f54fe="" class="center" id="firstDiv"><span data-v-859f54fe="" class="cur"><em
                    data-v-859f54fe="">¥</em>35</span><span data-v-859f54fe="" class="org"
                  style="display: none;">¥</span></div>
              <div data-v-859f54fe="" class="bottom" id="secondDiv" style="display: none;"></div>
              <div data-v-859f54fe="" class="button" id="threeDiv">点击购买会员</div>
            </div>
          </div>
        </div> -->
        <div data-v-c226bfef="" class="list-card" v-show="showinnerversion">
          <div data-v-859f54fe="" data-v-c226bfef="" class="card svip">
            <div data-v-859f54fe="" class="background" style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="background def_border"
              style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="tips" style="display: none;"><img data-v-859f54fe="" alt=""><span
                data-v-859f54fe=""></span></div>
            <div data-v-859f54fe="" class="info infoTypes">
              <div data-v-859f54fe="" class="top">7 days尊享版(内测版)</div>
              <div data-v-859f54fe="" class="center" id="firstDiv"><span data-v-859f54fe="" class="cur"><em
                    data-v-859f54fe="">¥</em>0.1</span><span data-v-859f54fe="" class="org"
                  style="display: none;">¥</span></div>
              <div data-v-859f54fe="" class="bottom" id="secondDiv" style="display: none;"></div>
              <div data-v-859f54fe="" class="button" id="threeDiv"  @click='buy("atomTask会员7天员工版",0.1,"sevendays")' >点击购买会员</div>
            </div>
          </div>
        </div>
        <div data-v-c226bfef="" class="list-card">
          <div data-v-859f54fe="" data-v-c226bfef="" class="card svip">
            <div data-v-859f54fe="" class="background" style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="background def_border"
              style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="tips" style="display: none;"><img data-v-859f54fe="" alt=""><span
                data-v-859f54fe=""></span></div>
            <div data-v-859f54fe="" class="info infoTypes">
              <div data-v-859f54fe="" class="top">7 days尊享版</div>
              <div data-v-859f54fe="" class="center" id="firstDiv"><span data-v-859f54fe="" class="cur"><em
                    data-v-859f54fe="">¥</em>10</span><span data-v-859f54fe="" class="org"
                  style="display: none;">¥</span></div>
              <div data-v-859f54fe="" class="bottom" id="secondDiv" style="display: none;"></div>
              <div data-v-859f54fe="" class="button" id="threeDiv"  @click='buy("atomTask会员7天尊享版",10,"sevenday")' >点击购买会员</div>
            </div>
          </div>
        </div>
        <div data-v-c226bfef="" class="list-card">
          <div data-v-859f54fe="" data-v-c226bfef="" class="card svip">
            <div data-v-859f54fe="" class="background" style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="background def_border"
              style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="tips" style="display: none;"><img data-v-859f54fe="" alt=""><span
                data-v-859f54fe=""></span></div>
            <div data-v-859f54fe="" class="info infoTypes">
              <div data-v-859f54fe="" class="top">30 days尊享版</div>
              <div data-v-859f54fe="" class="center" id="firstDiv"><span data-v-859f54fe="" class="cur"><em
                    data-v-859f54fe="">¥</em>49</span><span data-v-859f54fe="" class="org"
                  style="display: none;">¥</span></div>
              <div data-v-859f54fe="" class="bottom" id="secondDiv" style="display: none;"></div>
              <div data-v-859f54fe="" class="button" id="threeDiv"  @click='buy("atomTask会员30天尊享版",49,"month")' >点击购买会员</div>
            </div>
          </div>
        </div>
        <div data-v-c226bfef="" class="list-card">
          <div data-v-859f54fe="" data-v-c226bfef="" class="card svip">
            <div data-v-859f54fe="" class="background" style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="background def_border"
              style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="tips" style="display: none;"><img data-v-859f54fe="" alt=""><span
                data-v-859f54fe=""></span></div>
            <div data-v-859f54fe="" class="info infoTypes">
              <div data-v-859f54fe="" class="top">183 days尊享版</div>
              <div data-v-859f54fe="" class="center" id="firstDiv"><span data-v-859f54fe="" class="cur"><em
                    data-v-859f54fe="">¥</em>69</span><span data-v-859f54fe="" class="org"
                  style="display: none;">¥</span></div>
              <div data-v-859f54fe="" class="bottom" id="secondDiv" style="display: none;"></div>
              <div data-v-859f54fe="" class="button" id="threeDiv"   @click='buy("atomTask会员120天尊享版",69,"halfyear")'>点击购买会员</div>
            </div>
          </div>
        </div>
        <div data-v-c226bfef="" class="list-card">
          <div data-v-859f54fe="" data-v-c226bfef="" class="card svip">
            <div data-v-859f54fe="" class="background" style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="background def_border"
              style="background-image: url(&quot;null&quot;);"></div>
            <div data-v-859f54fe="" class="tips" style="display: none;"><img data-v-859f54fe="" alt=""><span
                data-v-859f54fe=""></span></div>
            <div data-v-859f54fe="" class="info infoTypes">
              <div data-v-859f54fe="" class="top">365 days尊享版</div>
              <div data-v-859f54fe="" class="center" id="firstDiv"><span data-v-859f54fe="" class="cur"><em
                    data-v-859f54fe="">¥</em>99</span><span data-v-859f54fe="" class="org"
                  style="display: none;">¥</span></div>
              <div data-v-859f54fe="" class="bottom" id="secondDiv" style="display: none;"></div>
              <div data-v-859f54fe="" class="button" id="threeDiv"  @click='buy("atomTask会员365天尊享版",99,"year")'>点击购买会员</div>
            </div>
          </div>
        </div>
      </div><!----><!---->
      <div class="el-overlay" style="z-index: 2009; display: none;">
        <div role="dialog" aria-modal="true" aria-labelledby="el-id-2961-16" aria-describedby="el-id-2961-17"
          class="el-overlay-dialog"></div>
      </div>
    </div>
    
    <div class="payzone" v-show="false">
    <div role="dialog" aria-modal="true" aria-labelledby="el-id-8939-11" aria-describedby="el-id-8939-12"
      class="el-overlay-dialog">
      <div class="el-dialog el-dialog--center vip-vis-dialog" tabindex="-1" style="--el-dialog-margin-top: 29vh;">
        <header class="el-dialog__header"><span role="heading" class="el-dialog__title"></span>
        
      
        </header>
        <div id="el-id-8939-12" class="el-dialog__body">
          <div class="pay-model-box">
            <div class="title-box">
              <div  class="money-choose">Select a payment method</div>
              <button class="payoptionbutton">Alipay pay</button>
              <button class="payoptionbutton">Wechat pay</button>
            </div>
            <div data-v-f3e5a9f5="" class="info-box">
              <div data-v-f3e5a9f5="" class="qrcode"><canvas data-v-f3e5a9f5="" id="qrcodeUrl" class="qrcodeUrl"
                  width="200" height="200" style="width: 200px; height: 200px;"></canvas>
                <div data-v-f3e5a9f5="" class="qr-des">Mobile payment please download Wechat first</div>
              </div>
              <div data-v-f3e5a9f5="" class="pay-info">
                <div data-v-f3e5a9f5="" class="pay-title">30 days</div>
                <div data-v-f3e5a9f5="" class="pay-description">Sweep the payment</div>
                <div data-v-f3e5a9f5="" class="pay-money"><em data-v-f3e5a9f5="">19.00</em><span
                    data-v-f3e5a9f5="">Yuan</span></div>
                <div data-v-f3e5a9f5="" class="pay-source">Payee: Beijing Caiche District Ming Technology Co. ,
                  Ltd. </div>
                <div data-v-f3e5a9f5="" class="pay-prefer"></div>
                <div data-v-f3e5a9f5="" class="pay-url">
                  <div data-v-22995277="" data-v-f3e5a9f5="">
                    <div data-v-22995277="" class="tagertext">May not be refundable for non-system reasons</div>
                    <div data-v-22995277="" class="tager">VIP Service Agreement</div>
                    <div class="el-overlay" style="z-index: 2007; display: none;">
                      <div role="dialog" aria-modal="true" aria-labelledby="el-id-8939-13"
                        aria-describedby="el-id-8939-14" class="el-overlay-dialog"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-v-f3e5a9f5="" class="statement-box">Note: If you purchase the same level of membership
              repeatedly, it will be renewed automatically.<br data-v-f3e5a9f5=""><br data-v-f3e5a9f5=""><span
                data-v-f3e5a9f5="">Doc translation quota is not enough, recommended to: Buy
                add-on-pack</span><span data-v-f3e5a9f5="" class="statement-boxs">Buy add-on-pack</span></div>
          </div>
        </div><!--v-if-->
      </div>
    </div>
    <button @click="activatemember('year')">完成购买,发送购买信息</button>
    </div>

    <div class="memberlistzone">
      <div class="title">会员权益明细</div>
    <a-table :dataSource="memberintrolist" :columns="columns" :pagination="false"  />
    </div>
    
  </div>
<userinfo class="userinfo" v-if="this.showuserinfo"> 
  <!-- <a href="/taskmanager" class="backbutton">--任务面板--</a> -->
  <div class="userdetail">
  
  
  <div class="tip">用户邮箱</div>
  <div>{{ this.userinfo }}</div>
  <div class="tip">会员等级</div>
  <div>{{ this.userlevel }} ❤️</div>
  <div>尊贵的用户，祝您事业长虹</div>
  <div class="tip">有效日期</div>
  <div>{{ this.startday }}- {{ this.endday }}</div>
  </div>
  
  <div class="memberlistzone">
    <a-table :dataSource="memberintrolist" :columns="columns" :pagination="false"  />
  
  </div>
          

    <!-- <div class="factorzone"> 
       <ul v-for="item in memberintrolist" :key="item.id" class="singlefactor" >
      <div > {{item.name}} </div>
    </ul> -->
    <!-- </div> -->
        </userinfo>
          

        
    
    
</div>


  
</template>
    
<script>
import axios from 'axios';
import configure_options from '../configure'
import { defineComponent, ref, toRefs, reactive } from 'vue';

export default defineComponent({
  components: {

  },
  setup() {
    var showmode = true;
    const state = reactive({
      checked1: true,
      checked2: false,
      checked3: false,
      selectedmode: ref("2"),
      showupdatebutton: false,
      showuserinfo:ref(true),
      showinnerversion:ref(false),

    });

    return {



           memberintrolist: [
          {
            key: '1',
            name: '定制皮肤',
            vip: "❤️",
            normal: '🚫',
          },
          {
            key: '2',
            name: '自定义任务标签',
            vip: "最高100个(不含系统自带标签)",
            normal: '可以创建10个(不含系统自带标签)',
          },
          {
            key: '3',
            name: '评价算法范围',
            vip: "可以手动选择",
            normal: '默认天数',
          },
          {
            key: '4',
            name: '月度完成任务首页查看',
            vip: "❤️",
            normal: '🚫',
          },
          {
            key: '5',
            name: '愿景透视图功能',
            vip: "❤️",
            normal: '🚫',
          },
          {
            key: '6',
            name: '自定义评价算法因子',
            vip: "最高100个(不含系统自带因子)",
            normal: '可以创建10个(不含系统自带因子)',
          },
          {
            key: '7',
            name: '故事书功能',
            vip: "❤️支持全年的故事",
            normal: '仅支持当月的故事',
          },
          {
            key: '8',
            name: 'rongbrain使用权',
            vip: "❤️vip功能支持",
            normal: '普通用户思维导图功能',
          },

          {
            key: '9',
            name: '任务推荐算法支持',
            vip: "❤️",
            normal: '🚫',
          },

          {
            key: 'last',
            name: '其他vip专项功能',
            vip: "正在研发中...",
            normal: '🚫',
          },
        ],

        columns: [
          {
            title: '会员权益',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'vip',
            dataIndex: 'vip',
            key: 'vip',
          },
          {
            title: '注册用户',
            dataIndex: 'normal',
            key: 'normal',
          },
        ],
      ...toRefs(state),
      version: configure_options["version"],
      showupdatebutton: false,
      showmode,
      userinfo:ref("noemail"),
      showprofile: ref(true),
      showversion: ref(false),
      shownormal: ref(false),
      showauthor: ref(false),
      userlevel: ref("注册"),
      startday:ref(""),
      showgiftinput:ref(false),
      giftcode:ref(""),
      endday:ref(""),
      hadgeneratedcode:ref([]),
      symbol:"<",
      showzone: { profile: true, lang: false, version: false, normal: false, author: false }
    };


  },
  methods: {


    buy1(){
        var goodsName ="测试商品";
        var count = 1;
        var price = 0.25
        var cost = 0.25;



        axios.get("http://127.0.0.1/paymoney/payinfo",{ params: {
                payName: "秦时明月",
                goodsName: goodsName,
                count: count,
                price: price,
                cost: cost,
            } }).then(response => {
            // If request is good...
            console.log(response.data);
            //在这里发送post请求
            
            
            const  payinfo = {
                payName: "秦时明月",
                goodsName: goodsName,
                count: count,
                price: price,
                cost: cost,
            }
         
          axios.post('http://127.0.0.1/paymoney/createOrder', payinfo)
            .then(response => {
                console.log(response.data)
                // document.getElementById("memeberzone").innerHTML = response.data
                window.$('body').append(window.$(response.data));
            })
            .catch(error=>{console.log(error)})
       


            })
          .catch((error) => {
              console.log('error ' + error);
            });





        // 像服务器发送付款请求确认
        // $.ajax({
        //     url: './payinfo',
        //     data: {
        //         payName: $('#username').val().trim(),
        //         goodsName: goodsName,
        //         count: count,
        //         price: price,
        //         cost: cost,
        //     },
        //     success: (res) => {
        //         // 请求正确时接收服务器的响应，再次确认是否需要生成订单
        //         if (res.code === 200) {
        //             let info = `
        //             购买人：${res.payName}\n
        //             购买商品：${res.goodsName}\n
        //             商品单价：${res.price}￥\n
        //             购买个数：${res.count}\n
        //             总共价钱：${res.cost}￥\n
        //             确认创建订单进行购买吗？
        //             `;
        //             if (confirm(info)) {
        //                 // 利用 ajax 发送订单生成订单请求
        //                 $.ajax({
        //                     type: 'post',
        //                     url: './createOrder',
        //                     data: {
        //                         payName: $('#username').val().trim(),
        //                         goodsName: goodsName,
        //                         price: price,
        //                         count: count,
        //                         cost: cost,
        //                     },
        //                     // 服务器向支付宝请求订单后，返回的是一个form表单，需要插入到浏览器中进行自动跳转
        //                     success: function (res) {
        //                         $('body').append($(res));
        //                     }
        //                 })
        //             }
        //         }
        //     }
        // })
  

    },

    buy(goodsname,pricepassed,buytimetype){

        var $=window.$;
        var goodsName = goodsname;
        var count = 1;
        var price = pricepassed;
        var cost = price*count;
      if(this.userinfo=="noemail"){
        alert("未获取到用户信息,稍后再试")
        return
      }

      // var  data= {
      //           payName:"秦时明月",
      //           goodsName: goodsName,
      //           count: count,
      //           price: price,
      //           cost: cost,
      //       }
      //       var env = configure_options["env"]

      //     console.log(configure_options["app_setting"][env])
      //     var serverhost = configure_options["app_setting"][env]["serverhost"]
        
      // axios.post(serverhost + '/paymoney/createOrder', data,{ withCredentials: true }).then(
      //   response => {

      //     console.log(response)
      //     alert(response.data.info)
      //           }

      // ).catch(error => {
      //   console.log(error)
      //   alert("用户名或密码错误")

      // })
        // 像服务器发送付款请求确认
        $.ajax({
            url: '/paymoney/payinfo',
            data: {
                payName:this.userinfo,
                goodsName: goodsName,
                count: count,
                price: price,
                cost: cost,
            },
            success: (res) => {
                // 请求正确时接收服务器的响应，再次确认是否需要生成订单
                if (res.code === 200) {
                    let info = `
                    购买人：${res.payName}\n
                    购买商品：${res.goodsName}\n
                    商品单价：${res.price}￥\n
                    购买个数：${res.count}\n
                    总共价钱：${res.cost}￥\n
                    确认创建订单进行购买吗？
                    `;
                    if (confirm(info)) {
                      // alert(buytimetype)
                        // 利用 ajax 发送订单生成订单请求
                        $.ajax({
                            type: 'post',
                            url: './paymoney/createOrder',
                            data: {
                                payName: this.userinfo,
                                goodsName: goodsName,
                                price: price,
                                count: count,
                                cost: cost,
                                buytimetype:buytimetype,
                                goodstype:"gtd",
                            },
                            // 服务器向支付宝请求订单后，返回的是一个form表单，需要插入到浏览器中进行自动跳转
                            success: function (res) {
                                $('body').append($(res));
                            }
                        })
                    }
                }
            }
        })
    
    },

    async  getgift() {
  // 假设兑换码为 exchangeCode，你可以根据实际情况获取用户输入等
  const exchangeCode = this.giftcode;

  try {
    // 使用 Axios 发起 POST 请求，将兑换码提交给服务器
    const response = await axios.post('/v1/activategift', { giftcode: exchangeCode });

    // 服务器返回的数据
    const responseData = response.data;

    // 根据服务器返回的数据进行处理
    if (responseData.success) {
      // 重载部分信息
      console.log('兑换成功，礼品卡信息:', responseData.giftInfo);
    } else {
      console.log("work good")
      alert(response.data.info)
      this.getuser()
    }
  } catch (error) {
    
    alert(error.response.data.info)
    console.error('发生错误:', error.message);
  }
}

,
    showrightcontent(type) {
      // alert(type)

      console.log(type)
      for (const key in this.showzone) {
        var newname = "show" + key
        if (key == type) {

          this[newname] = true;
          // 江别的全部置为false

          // this.showversion = true;
          // this.showprofile = true;
          console.log(`对应的版本信息${newname}`)
          // console.log(`对应的版本信息${this.showversion} type is ${typeof this.showversion}`)
          // console.log(`对应的版本信息${this.showprofile}`)
        } else {

          this[newname] = false;

        }
      }
    },
  

    getuser() {
      var env = configure_options["env"]

      console.log(configure_options["app_setting"][env])
      var serverhost = configure_options["app_setting"][env]["serverhost"]
     var _this=this
      axios.get(serverhost + "/v1/userinfo").then(
        response => {
          // alert("该函数已经被调用")
          var data = response.data
          console.log("获取用户信息如下")
          console.log(data)
          this.userinfo = data["user"]
          var userlevel = data["userlevel"]
          var startdayserver = data["startday"]
          var enddayserver = data["endday"]
          // alert(`${startdayserver} ${enddayserver} ${userlevel}`)
        
          if (userlevel == "normal") {
            this.userlevel = "注册"
            _this.showuserinfo = false
          }
          else if (userlevel == "vip") {
            this.userlevel = "尊贵的付费用户"
            _this.showuserinfo = true
            this.startday = startdayserver
            this.endday = enddayserver
          }
          else if (userlevel == "adminclass2") {
            this.userlevel = "创始会员"
            _this.showuserinfo = false
            _this.showinnerversion = true
          }

          else if (userlevel == "admin" ||userlevel == "adminclass2") {
            this.userlevel = "管理员"
            _this.showuserinfo = true
           
            this.startday = startdayserver
            this.endday = enddayserver
          }
          else{
            this.userlevel = "注册"
            _this.showuserinfo = false
           
          }
          console.log("~~~~~~~~~~~~~~~我正在打印用户的信息部分~~~~~~~~~")
          console.log(data);
          // alert(`${this.userlevel},${this.showuserinfo}`)
        }
      ).catch(error => { console.log(error) })
    },
    activatemember(timerange) {
      console.log(configure_options)
      var env = configure_options["env"]
      console.log(configure_options["app_setting"][env])
      var serverhost = configure_options["app_setting"][env]["serverhost"]
      var userinfo = { "paykey": "test", "timerange": timerange }
      alert("验证成功后,将激活会员")

      axios.post(serverhost + '/v1/activateMember', userinfo).then(
        response => {

          console.log(response)
          alert(response.data.info)
                }

      ).catch(error => {
        console.log(error)
        alert("用户名或密码错误")

      })

    }

  },
  mounted() {

    var env = configure_options["env"]
    // alert(env)
    var _this = this
    if (env == "product") {
      _this.showmode = false
      _this.getuser()
      
    } else {

      _this.showmode = true
      _this.getuser()
   
      // alert(_this.showmode)
    }




  }
});





</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.memeberzone{
  display: flex;
  height: 100vh;
  background:#d1e9e0;
  
.memberoptions{

  margin: 0 auto;
  display: flex;


  .money-list {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  // padding-left: 40px;
  // padding-right: 40px;
  max-height: 30vh;
  margin-bottom: 20px;
  .title{
    text-align: left;
    font-size: large;
      color: black;
      font-weight: 700;
      margin-bottom: 5px;
  }
}


  flex-direction:column;
  .memberlistzone{
  margin-top: 5%;
  max-height: 50vh;
  overflow: auto;
  .title{
    text-align: left;
    font-size: large;
      color: black;
      font-weight: 700;
      margin-bottom: 5px;
  }
}
}

}


.userinfo {
  display:flex;
  flex-direction:column;
  margin-top: 20px;
  padding: 10px;
 
  margin:0 auto;
  
.userdetail{
  text-align: left;
  border: 1px solid black;
  border-radius: 5%;
  padding:20px;
  min-width:20vw;
  max-height: 30vh;
  background: #8ae7c7;
  overflow: auto;
}
.memberlistzone{
  margin-top: 1%;
  max-height: 50vh;
  overflow: auto;
}
}


.title-box {
  
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;

  .money-titles {
    border: 1px solid rgba(.8666666746139526, .8980392217636108, .9333333373069763, .2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
  }
}





.titleWidth {
  width: 150px;
}

.backbutton{
  font-family: "handwriting1";
}


.list {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.list-card {
  margin-bottom: 20px;
}

.card {
  border: 1px solid #d4d4d4;
  -webkit-box-shadow: 0 12px 18px 0 rgb(39 173 112 / 10%);
  box-shadow: 0 12px 18px #27ad701a;
  border-radius: 12px;
  width: 182px;
  height: 176px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-transition: borderColor .4s;
  transition: borderColor .4s;
  margin-bottom: 20px;



  .button {
    width: 134px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background: #00b977;
    -webkit-box-shadow: 0 12px 18px 0 rgba(255, 188, 73, .06);
    box-shadow: 0 12px 18px #ffbc490f;
    border-radius: 19px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    // opacity: 0;
    // -webkit-transition: opacity 12s;
    // transition: opacity 12s;
    margin: auto;
  }




  .background {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    display: none;
  }


  .center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 18px;
    margin-top: 18px;


    .cur {
      font-size: 30px;
      color: #212121;
      font-weight: 900;
    }
  }
}

.payzone{
  background:#9eafa9;
 
  .statement-box {
    padding-top: 25px;
    color: #999;
    font-size: 14px;
    text-align: center;
    max-width: 85%;
    margin: 0 auto;
}

.payoptionbutton{
  border:#000 3px ;
}


.btnChoose {
    width: 140px;
    height: 40px;
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 20px;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


  .title-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
 
}
 .qrcode {
    width: 220px;
    margin-right: 30px;
}

.pay-info{

  .pay-title{
    font-size: 16px;
    font-weight: 500;
    color: #00b977;
    margin-bottom: 24px;
    font-weight: 700;
}
}
  .info-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 38px;
    text-align: left;
}


}
#back {
  position: absolute;
  ;
  left: 10%;
}


.factorzone{
      display: flex;
      // grid-template-columns: 1fr 1fr ; 
      // height:10vh;
      .singlefactor{
        border: 1px solid blue;
      // background: rgb(206, 9, 9);
      background: blue;
      text-align: center;
      // height:20px;
      color: white;
      margin-left:1px;
      padding:3px;
      border-radius: 10%;
      font-weight: 600;
    }
   
    }

#setting {
  display: flex;
  flex-direction: column;
}

#settingzone {
  display: flex;
  flex-direction: columns;
  /* background:#ebf3f2; */
}

.leftnav {
  height: 100%;
  background: red;
  color: white;
  width: 90px;
  text-align: left;
  margin-left: 0px;
}

#settingtitle {
  margin-top: 20px;
  font-size: 20px;
}


#updatebutton {
  /* background:none; */
  border-radius: 2px;
  position: absolute;
  bottom: 0%;
  right: 0%;
  min-width: 50px;
  border-style: none;
  background: #ff9000;
}

#reference {
  font-family: fangsong;
  text-align: left;
  position: absolute;
  left: 40%;
}

.rightcontent {
  margin-top: 20px;
  text-align: left;
}

#temp {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

#menu {
  display: flex;
  flex-direction: column;
}

#leftmenugroup {
  display: flex;
  flex-direction: column;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}


.tip{
font-size: larger;
font-weight: 600;
padding-top: 14px;
text-align: left;
}


.giftcodebutton{
  font-size: 12px;
  color: red;
}
</style>
    
<template>
 <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22" height="22"
	viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
    <title>全屏</title>
<circle class="st0" cx="16" cy="16" r="4"/>
<path class="st0" d="M3,11V6c0-1.1,0.9-2,2-2h5"/>
<path class="st0" d="M10,28H5c-1.1,0-2-0.9-2-2v-5"/>
<path class="st0" d="M29,21v5c0,1.1-0.9,2-2,2h-5"/>
<path class="st0" d="M22,4h5c1.1,0,2,0.9,2,2v5"/>
</svg>

    </template>
    <script>
    export default {
       name: 'focusicon',
    }
    </script>
    
    <style scoped>
    .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st1{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
	.st2{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:6,6;}
	.st3{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:4,4;}
	.st4{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;}
	.st5{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-dasharray:3.1081,3.1081;}
	.st6{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:4,3;}
    </style>
<template>
    <div class="container"   :style="{
      '--animation-speed': animationSpeed,
      '--sandColor': watercolor,
      '--tbackground': tbackground
    }">
      <div class="hourglass">
        <!-- 沙漏上部 -->
        <div class="top"></div>
  
        <!-- 沙漏下部 -->
        <div class="bottom">
          <div class="bottom_sand"></div>
        </div>
  
        <!-- 沙漏中间连接 -->
        <div class="mid"></div>
  
        <!-- 沙粒动画 -->
        <div class="drops">
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
            <div class="drop"></div>
        </div>
  
        <!-- 沙漏的木架 -->
        <div class="woods">
          <div class="wood"></div>
          <div class="wood"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Hourglass",
    props: {
      duration: {
        type: Number,
        default: 60, // 默认倒计时 60 秒
        validator: (value) => value > 0,
      },
      tbackground:{
        type:String,
        default:"white"
      },
      waterColor:{
        type:String,
        default:"blue",
      }
    },
    computed: {
      animationSpeed() {
        console.log("⬆️⬆️⬆️⬆️⬆️")
        console.log(this.duration)
        return `${this.duration}s`; // 将持续时间转换为 CSS 的动画时间
      },

      watercolor() {
        console.log("⬆️⬆️⬆️⬆️⬆️")
  
        return `${this.waterColor}`; // 将持续时间转换为 CSS 的动画时间
      },
    },
  };
  </script>
  
  <style  scoped lang="scss"> 

  .container {
    position: absolute;
    width: 420px;
    height: 420px;
   
    border: 2px solid #fff;
    border-radius: 4px;
    background:var(--tbackground);
  }
  
  .hourglass {
    position: relative;
    height: 100%;
  }
  
  .wood {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color:var(--woodColor);
    width: 220px;
    height: 30px;
    &:nth-child(1) {
      top: 20px;
    }
    &:nth-child(2) {
      bottom: 20px;
    }
  }
  
  .top {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 200px;
    height: 110px;
    border: 2px solid  rgb(226, 222, 200);
    border-radius: 20px 20px 200px 200px;
    transform: translateX(-50%) scale(1, 1.5);
    transform-origin: bottom;
    overflow: hidden;
  
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0%;
      background-color: var(--sandColor);
      animation: sandHeightTop var(--animation-speed) linear 1;
    }
  
    @keyframes sandHeightTop {
      0% {
        height: 100%;
      }
      100% {
        height: 0%;
      }
    }
  }
  
  .bottom {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 110px;
    border: 2px solid rgb(226, 222, 200);
    border-radius: 200px 200px 20px 20px;
    transform: translateX(-50%) scale(1, 1.5);
    transform-origin: top;
    overflow: hidden;
  
    .bottom_sand {
      position: absolute;
      top: 100%;
      left: 0%;
      width: 100%;
      height: 100%;
      background-color:var(--sandColor);
      transform: scale(3, 1);
      animation: sandTop var(--animation-speed) linear 1;
      animation-fill-mode: forwards;
      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   left: 50%;
      //   width: 100px;
      //   height: 100px;
      //   border-radius: 5px;
      //   background-color: var(--sandColor);
      //   transform: translateX(-50%) rotate(45deg);
      //   animation: sandAngle var(--animation-speed) linear 1;
      //   // filter: blur(2px);
      // }
    }
  
    @keyframes sandTop {
      0% {
        top: 100%;
      }
      80% {
        top: 20%;
      }

      100% {
        top: 0%;
      }
    }
  
    @keyframes sandAngle {
      0% {
        border-radius: 5px;
      }
      100% {
        border-radius: 50px;
      }
    }
  }
  
  .mid {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 6px;
    border: solid var(--woodColor);
    border-width: 0 3px;
    transform: translate(-50%, -50%);
    transform-origin: center;
    background-color: var(--sandColor);
  
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--sandColor);
      width: 12px;
      height: 14px;
      border-radius: 50%;
      // filter: blur(3px);
    }
  }
  
  .drop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--sandColor); /* 使用相同的沙粒颜色 */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  
  --dropx: -50%;
  opacity: 1; /* 初始时保持沙粒颜色一致，不透明 */
  animation: sandDrop 5s ease-in infinite, dropVis var(--animation-speed) linear infinite;

  @for $i from 0 through 9 {
    &:nth-child(#{$i + 1}) {
      animation-delay: #{1 * $i}s; /* 每个沙粒有不同的延迟时间 */
      --dropx: #{(($i * 230) % 100) - 100}%;
    }
  }

  @keyframes sandDrop {
    from {
      top: 50%;
      transform: translate(-50%, -50%);
    }
    to {
      top: calc(50% + 160px);
      transform: translate(var(--dropx), -50%) scale(0.25);
    }
  }

  @keyframes dropVis {
    0% {
      opacity: 1; /* 确保动画开始时，沙粒颜色和透明度一致 */
    }
    100% {
      opacity: 1; /* 保持透明度不变 */
    }
  }
}


  </style>
  
<template>
    <svg t="1602774165074" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2453"
        width="18px" height="18px">
        <title>点击选择任务的属性</title>
        <path
            d="M344.064 194.56c-72.704 0-132.096 59.392-132.096 132.096s59.392 132.096 132.096 132.096c72.704 0 132.096-59.392 132.096-132.096S416.768 194.56 344.064 194.56z m0 185.344c-29.696 0-53.248-23.552-53.248-53.248 0-29.696 23.552-53.248 53.248-53.248 29.696 0 53.248 23.552 53.248 53.248 0 29.696-24.576 53.248-53.248 53.248z m656.384 143.36L512 32.768C496.64 17.408 476.16 9.216 454.656 9.216H79.872C35.84 9.216 0 45.056 0 89.088v345.088c0 21.504 8.192 40.96 23.552 56.32l503.808 501.76c15.36 15.36 35.84 23.552 57.344 23.552 21.504 0 41.984-8.192 56.32-23.552L1000.448 634.88c31.744-30.72 31.744-80.896 0-111.616zM583.68 934.912l-503.808-501.76V89.088h374.784l488.448 490.496L583.68 934.912z"
            p-id="2454" fill="#d81e06"></path>
    </svg>
</template>
       
<script>
export default {
    name: 'tagicon',
}
</script>
       
<style scoped></style>
<template>
  <div class="battery-container">
    <!-- 电池容器 -->
    <div class="battery-wrapper">
      <div class="battery">
        <div class="battery-level" :style="batteryStyle"></div>
      </div>

      <!-- 悬浮框 -->
      <div class="popup popcard" >
        <div class="title">进度信息</div>
        <div class="info">
        <div>项目进度: {{ formattedProgress }}%</div>
         <div>计划时间: {{ esdevotedtime }}</div>
        <div>状态: {{ batteryStatus }}</div>
      </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed,defineProps } from 'vue';

// Props
const props = defineProps({
  progress: {
    type: Object,
    required: true,
    default:  () => ({}),
  },
});

// 电池级别样式
const batteryStyle = computed(() => ({
  width: `${Math.min(props.progress?.Progressbar, 100)}%`, // 限制最大宽度为 100%
  backgroundColor: getBatteryColor(props.progress?.Progressbar), // 根据进度设置颜色
}));

// 格式化进度为两位小数
const formattedProgress = computed(() => {
  const progressValue = Math.min(props.progress?.Progressbar, 100);
  return progressValue.toFixed(2) ;
});

// 根据进度返回状态
const batteryStatus = computed(() => {
  if (props.progress?.Progressbar >= 80) return '进度优等';
  if (props.progress?.Progressbar >= 30) return '进度中等';
  return '进度低';
});



const esdevotedtime = computed(() => {
  var hour = (props.progress?.Esdevotedtime/60).toFixed(2) 
  return hour +" hour"
});

// 提供电池建议
// const batterySuggestion = computed(() => {
//   if (props.progress >= 80) return '可以正常使用';
//   if (props.progress >= 30) return '建议尽快充电';
//   return '请立即充电';
// });

// 根据电量进度返回颜色
function getBatteryColor(progress) {
  if (progress?.Progressbar >= 66) return '#4CAF50'; // 绿色
  if (progress?.Progressbar >= 33) return '#FF9800'; // 橙色
  return '#F44336'; // 红色
}
</script>

<style scoped lang="scss">
.battery-container {
  position: relative; /* 相对定位用于悬浮框定位 */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.battery-wrapper {
  position: relative; /* 包裹电池和悬浮框 */
}

.battery {
  width: 60px;
  height: 10px;
  border: 1px solid #000;
  border-radius: 4px;
  position: relative;
  background-color: #e0e0e0;
  overflow: hidden;
}

.battery-level {
  height: 100%;
  transition: width 0.5s ease-in-out;
  border-radius: 2px;
}

/* 悬浮框样式 */
.popup {
  position: absolute;
  top: -60px; /* 放置在电池上方 */
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #ddd;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  display: none; /* 默认隐藏 */  
}



.popcard{
  background-color: white;
  padding: 2px 2px;
  color: black;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10%;
  opacity: 1.0;
  z-index: 1000;
  .title{
    width: 100%;
    color: white;
    background: red;
    padding: 5px 10px;
  }
  .info{
    display: flex;
    flex-direction: column;
    gap:2px;

  }
}



.battery-wrapper:hover .popup {
  display: block; /* 鼠标悬浮时显示悬浮框 */
}
</style>

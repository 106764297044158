<template>
    <div v-if="showCard" class="card">
      <div class="card-content">
        <p>{{ message }}</p>
      </div>
      <div class="countdown-bar" :style="{ width: countdownWidth + '%', backgroundColor: countdownColor }"></div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted ,defineProps} from 'vue';
  import emitter from '../eventbus';
  // 接收父组件传递的消息和倒计时时间
  const props = defineProps({
    message: {
      type: String,
      default: "This is a message from the agent."  // 默认消息
    },
    duration: {
      type: Number,
      default: 10  // 默认倒计时秒数
    }
  });
  
  // 定义响应式数据
  const countdownWidth = ref(100);  // 倒计时条宽度
  const showCard = ref(false);  // 控制卡片是否显示
  const countdownColor = ref('#00ff00');  // 初始颜色（绿色）
  
  // 启动倒计时
  function startCountdown() {
    let timeLeft = props.duration;  // 剩余时间
    const interval = setInterval(() => {
      timeLeft--;
      countdownWidth.value = (timeLeft / props.duration) * 100;  // 更新倒计时条宽度
  
      // 动态改变颜色（绿色到红色过渡）
      const red = Math.min(255, 255 * (1 - timeLeft / props.duration));
      const green = Math.min(255, 255 * (timeLeft / props.duration));
      countdownColor.value = `rgb(${red}, ${green}, 0)`;  // 颜色从绿色变为红色
  
      // 当倒计时结束时，停止动画并隐藏卡片
      if (timeLeft <= 0) {
        clearInterval(interval);
        showCard.value = false;
        emitter.emit("closenotification", 1);
      }
    }, 1000);
  }
  
  // 当组件挂载时显示卡片并启动倒计时
  onMounted(() => {
    // 模拟智能体推送消息，延时0.5秒显示卡片
    setTimeout(() => {
      showCard.value = true;
      startCountdown();  // 启动倒计时
    }, 500);
  });
  </script>
  
  <style scoped>
  .card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: relative;
    margin: 0 auto;
  }
  
  .card-content {
    margin-bottom: 10px;
    font-size: 16px;
  
  }
  
  .countdown-bar {
    height: 5px;
    border-radius: 5px;
    transition: width 1s linear, background-color 1s linear;  /* 控制进度条宽度和平滑变化 */
  }
  </style>
  
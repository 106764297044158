<template>
    <div class="heatmap">
      <div
        v-for="(day, index) in completeData"
        :key="index"
        class="day"
        :data-level="day.level"
        :data-tooltip="`${formatDate(day.date)} - Activity Level: ${day.level}`"
      ></div>
    </div>
  </template>
  
  <script>
  import { defineComponent, computed, onMounted, ref } from "vue";
  
  export default defineComponent({
    name: "Heatmap",
    props: {
      data: {
        type: Array,
        default: null, // 如果未传递数据，则自动生成默认数据
      },
      colorLevels: {
        type: Array,
        default: () => [
          "#fde0e0", // Level 0
          "#fab3b3", // Level 1
          "#f68686", // Level 2
          "#f15959", // Level 3
          "#e62e2e", // Level 4
          "#a50000", // Level 5
        ],
      },
    },
    setup(props) {
      // 定义内部数据状态
      const filledData = ref([]);
  
      // 生成默认数据的函数
      const generateDefaultData = () => {
        const startDate = new Date(2022, 0, 1); // 从 2022 年 1 月 1 日开始
        return Array.from({ length: 365 }, (_, index) => {
          const date = new Date(startDate);
          date.setDate(startDate.getDate() + index);
          return {
            date: date,
            level: Math.floor(Math.random() * 6), // 默认生成 0-5 的随机值
          };
        });
      };
  
      // 格式化日期为 YYYY-MM-DD
      const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split("T")[0];
      };
  
      // 计算属性：根据传入数据或内部生成数据选择完整数据
      const completeData = computed(() => props.data || filledData.value);
  
      // 如果没有传入数据，生成默认数据
      onMounted(() => {
        if (!props.data) {
          filledData.value = generateDefaultData();
        }
      });
  
      // 返回模板中使用的变量和方法
      return {
        completeData,
        formatDate,
      };
    },
  });
  </script>
  
  <style scoped>
  .heatmap {
    display: grid;
    grid-template-columns: repeat(52, 12px); /* 52 周 */
    grid-template-rows: repeat(7, 12px);    /* 7 天 */
    gap: 2px;
  }
  
  .day {
    width: 12px;
    height: 12px;
    background-color: #fde0e0; /* 默认颜色，无活动 */
    border-radius: 2px;
    position: relative;
  }
  
  .day[data-level="1"] { background-color: #fab3b3; }
  .day[data-level="2"] { background-color: #f68686; }
  .day[data-level="3"] { background-color: #f15959; }
  .day[data-level="4"] { background-color: #e62e2e; }
  .day[data-level="5"] { background-color: #a50000; }
  
  /* Tooltip 样式 */
  .day:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: black;
    color: white;
    font-size: 10px;
    padding: 3px 5px;
    border-radius: 3px;
    white-space: nowrap;
    pointer-events: none;
    opacity: 0.9;
  }
  
  .day:hover {
    transform: scale(1.2);
    transition: transform 0.2s;
  }
  </style>
  
<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 64 64" height="22px" width="22px"  enable-background="new 0 0 64 64" xml:space="preserve">
<g>
	<g>
		<g>
			<path fill="#5093FF" d="M32,0c17.7,0,32,14.3,32,32S49.7,64,32,64S0,49.7,0,32S14.3,0,32,0z"/>
		</g>
	</g>
	<g>
		<defs>
			<path id="SVGID_1_" d="M32,0c17.7,0,32,14.3,32,32S49.7,64,32,64S0,49.7,0,32S14.3,0,32,0z"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlink:href="#SVGID_1_"  overflow="visible"/>
		</clipPath>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#316CFF" d="M13,64c0-9.3,8.5-17,19-17h0c10.4,0,19,7.7,19,17"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<circle fill="#FFFFFF" cx="32" cy="50" r="6"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#F7BE8F" d="M36.1,45.3v4.6c0,2.3-1.9,4.1-4.1,4.1c-1.1,0-2.2-0.5-2.9-1.2c-0.7-0.8-1.2-1.8-1.2-2.9v-4.6H36.1z"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#FAD3A1" d="M36.3,48.3C34.9,48.8,33.5,49,32,49s-2.9-0.2-4.3-0.7L26,47.5c-3.2-1.8-5.4-4.8-5.9-8.2
					C20,38.7,20,38.2,20,37.7c0-1,0-3.1,0-4.9c0-1.7,0-9.8,0-9.8h24c0,0,0,8.1,0,9.8c0,1.8,0,3.8,0,4.9c0,0.5,0,1.1-0.1,1.6
					c-0.2,1.5-0.7,2.8-1.5,4.1c-1,1.7-2.5,3.1-4.4,4.1C37.4,47.8,36.9,48,36.3,48.3z"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#262647" d="M26.5,32c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5C25,32.7,25.7,32,26.5,32z
					"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#262647" d="M36,33.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5C36.7,35,36,34.3,36,33.5z"
					/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#F7BE8F" d="M33,39c0,0.6-0.4,1-1,1s-1-0.4-1-1H33z"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#F4B28E" d="M32,44.5c-1.6,0-3.1-0.9-3.9-2.2C28,42,28,41.7,28.3,41.6c0.2-0.1,0.5-0.1,0.7,0.2
					c0.6,1.1,1.8,1.8,3,1.8s2.4-0.7,3-1.8c0.1-0.2,0.4-0.3,0.7-0.2c0.2,0.1,0.3,0.4,0.2,0.7C35.1,43.6,33.6,44.5,32,44.5z"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#262647" d="M46,26v7h-2v-5.4l-5.4-4.2c-1.5,4-5.9,6.8-10.3,6.8c-3,0-6.4-1.3-8.3-3.4v0.9V33h-2v-8.5
					c0-3.1,1-5.6,2.7-7.5c2.6-3,6.5-5.1,10.3-5c3.1,0.1,5.4,1.1,9,0c0.3,0.5,0.5,1.2,0.3,2.2c-0.1,0.8-0.2,1-0.3,1.5l0,0
					C43.5,18.3,46,21.2,46,26z"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#F7BE8F" d="M20,37c-1.7,0-3-1.3-3-3c0-0.8,0.3-1.6,0.9-2.1c0.5-0.5,1.3-0.9,2.1-0.9V37z"/>
			</g>
		</g>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<path fill="#F7BE8F" d="M44,37c1.7,0,3-1.3,3-3c0-0.8-0.3-1.6-0.9-2.1C45.6,31.3,44.8,31,44,31V37z"/>
			</g>
		</g>
	</g>
</g>
</svg>

         </template>
       <script>
         export default {
           name: "usericon",
         };
         </script>
             
         <style scoped>
         </style>
<template>
  <div class="area_addproject_right">
    <p class="title">
      创建目标 <span class="goal-name">{{ goal.goaltext }}</span> 的项目
    </p>

    <a-input
      class="project-input"
      v-model:value="projecttext_input"
      placeholder="请输入项目名称"
    />

    <!-- 复选框和提示区域 -->
    <div class="checkbox-section">
      <a-checkbox v-model:checked="isProjectCompletable">
        此项目清晰且可在合理时间内实现吗?
        <a-tooltip title="请输入一个可在合理时间内完成的项目，并勾选左侧复选框">
          <infoicon class="info-icon" />
        </a-tooltip>
      </a-checkbox>
      <div class="checkbox-hint">
        {{ isProjectCompletable ? '😄 没问题，我可以完成！' : '😭 可能有些难度，还需再考虑。' }}
      </div>
    </div>
    <div class="devotedtimearea" title="滑动选择预期完成该任务需要多少时间(minute)"><a-slider  v-model:value="esdevotedtime_fromslider" :max=3000  /></div>
             
    <button
      @click="createprojectingoalfunc(goal)"
      class="add-project-button"
      :disabled="!isProjectCompletable || !projecttext_input"
    >
      提交
    </button>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import infoicon from '../svg/infoicon.vue';
import emitter from "../eventbus.js"; 
const axios = require('axios').default;
import configure_options from '../configure';

export default defineComponent({
  name: 'createprojectingoal',
  components: {
    infoicon,
  },
  props: {
    msg: String,
    goal: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isProjectCompletable = ref(false);
    const projecttext_input = ref("");
    const  esdevotedtime_fromslider = ref(0)
    function createprojectingoalfunc(goal) {
      const env = configure_options["env"];
      const serverhost = configure_options["app_setting"][env]["serverhost"];

      if (!projecttext_input.value) {
        alert("请输入项目名，项目名不能为空");
        return;
      }

      if (!isProjectCompletable.value) {
        alert("请输入可以完成的项目来增加信心哟");
        return;
      }

      const project = { goalcode: goal.goalcode, projectname: projecttext_input.value,esdevotedtime:esdevotedtime_fromslider.value };
      axios.post(`${serverhost}/v1/createproject`, project)
        .then(response => {
          console.log(response.data);
          document.getElementById("createprojectzone").style.display = "none";
          document.getElementById("goaltreedatapart").style.opacity = 1;
          document.getElementById("goaltreedatapart").style.pointerEvents = "auto";
          document.getElementById("top").style.opacity = 1;
          emitter.emit("turnoffshowaddprojectbox", "createproject_component_ask");
          emitter.emit("refresh_goaltree", "createproject_component_ask");
        })
        .catch(error => {
          console.log(error);
          if (error.response && error.response.status == 802) {
            alert("该项目已经创建，请使用别的项目名");
          }
        });
    }

    return {
      projecttext_input,
      isProjectCompletable,
      createprojectingoalfunc,
      esdevotedtime_fromslider,
    };
  },
});
</script>

<style scoped lang="scss">
.area_addproject_right {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: Arial, sans-serif;
}

.title {
  font-size: 14px; /* 恢复为正常字体大小 */
  margin-bottom: 10px;
  line-height: 1.5; /* 恢复正常行距 */
  color: #333;
}

.goal-name {
  color: #1a73e8;
  font-weight: bold;
}

.project-input {
  width: 100%;
  margin-bottom: 15px;
  height: 35px;
}

.checkbox-section {
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 0px;
}

.checkbox-hint {
  font-size: 0.9em;
  color: #666;
  margin-left: 1px;
}

.info-icon {
  font-size: 1.2em;
  color: #999;
  margin-top: 5px;
  cursor: pointer;
  transition: color 0.3s;
}

.info-icon:hover {
  color: #1a73e8;
}

.add-project-button {
  background-color: #1a73e8;
  color: white;
  padding: 3px 10px; /* 调整按钮的内边距 */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.add-project-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-project-button:hover:not(:disabled) {
  background-color: #1454b8;
}
</style>

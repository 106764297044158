<template>
 <svg version="1.1" height="20px" width="20px"    id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
<title>快速增加任务</title>
   <g>
	<path d="M0,0v60h60V0H0z M58,58H2V2h56V58z"/>
	<polygon points="29,51 31,51 31,31 51,31 51,29 31,29 31,9 29,9 29,29 9,29 9,31 29,31 	"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
    </template>
    
    <script>
    export default {
       name: 'plusblock',
    }
    </script>
    
    <style scoped>
    </style>
<template>
    
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" height="18px" width="20x" style="enable-background:new 0 0 50 50;" xml:space="preserve">
<g id="Layer_1_1_">
	<path d="M1.118,27.411l4.271,1.412c0.176,0.909,0.415,1.804,0.714,2.673l-2.998,3.363l0.292,0.602
		c0.506,1.044,1.094,2.059,1.746,3.017l0.376,0.553l4.391-0.914c0.609,0.7,1.268,1.358,1.968,1.968l-0.914,4.39l0.552,0.376
		c0.959,0.653,1.975,1.241,3.018,1.747l0.602,0.292l3.363-2.998c0.869,0.299,1.764,0.538,2.673,0.714l1.413,4.271l0.667,0.048
		c0.578,0.043,1.16,0.071,1.748,0.071s1.17-0.028,1.748-0.07l0.667-0.048l1.412-4.271c0.909-0.176,1.804-0.415,2.673-0.714
		l3.363,2.998l0.602-0.292c1.044-0.506,2.059-1.094,3.017-1.746l0.553-0.376l-0.914-4.391c0.7-0.609,1.358-1.268,1.968-1.968
		l4.39,0.914l0.376-0.552c0.652-0.958,1.24-1.974,1.747-3.018l0.292-0.602l-2.998-3.364c0.299-0.869,0.538-1.764,0.714-2.673
		l4.271-1.413l0.048-0.667C48.972,26.166,49,25.584,49,24.996s-0.028-1.17-0.07-1.748l-0.048-0.667l-4.271-1.412
		c-0.176-0.909-0.415-1.804-0.714-2.673l2.998-3.363l-0.292-0.602c-0.506-1.044-1.094-2.059-1.746-3.017l-0.376-0.553l-4.391,0.914
		c-0.609-0.7-1.268-1.358-1.968-1.968l0.914-4.39l-0.552-0.376c-0.959-0.653-1.975-1.241-3.018-1.747l-0.602-0.292L31.501,6.1
		c-0.869-0.299-1.764-0.538-2.673-0.714l-1.413-4.271l-0.667-0.048c-1.155-0.083-2.34-0.083-3.495,0l-0.667,0.048l-1.412,4.271
		C20.265,5.562,19.37,5.801,18.501,6.1l-3.363-2.999l-0.602,0.292c-1.042,0.506-2.058,1.093-3.017,1.747l-0.553,0.376l0.914,4.391
		c-0.7,0.609-1.358,1.268-1.968,1.968l-4.39-0.914l-0.376,0.552c-0.653,0.959-1.241,1.975-1.747,3.018l-0.292,0.602l2.998,3.363
		c-0.299,0.869-0.538,1.764-0.714,2.673L1.12,22.582l-0.048,0.667C1.028,23.826,1,24.408,1,24.996s0.028,1.17,0.07,1.748
		L1.118,27.411z M3.024,24.058l4.135-1.368l0.098-0.599c0.192-1.187,0.503-2.352,0.924-3.461l0.215-0.567l-2.899-3.252
		c0.289-0.551,0.602-1.092,0.936-1.616l4.247,0.884l0.384-0.469c0.762-0.93,1.619-1.788,2.549-2.548l0.469-0.384L13.198,6.43
		c0.525-0.334,1.065-0.647,1.616-0.936l3.252,2.9l0.567-0.215c1.11-0.421,2.275-0.732,3.462-0.924l0.599-0.098l1.367-4.135
		c0.622-0.03,1.255-0.03,1.877,0l1.368,4.135l0.599,0.098c1.187,0.192,2.352,0.503,3.461,0.924l0.567,0.215l3.252-2.899
		c0.551,0.289,1.092,0.602,1.616,0.936l-0.884,4.247l0.469,0.384c0.93,0.762,1.788,1.619,2.548,2.549l0.384,0.469l4.248-0.884
		c0.334,0.524,0.647,1.064,0.936,1.616l-2.899,3.252l0.215,0.567c0.421,1.111,0.732,2.276,0.924,3.462l0.098,0.599l4.135,1.367
		C46.991,24.369,47,24.681,47,24.996s-0.009,0.627-0.024,0.938l-4.135,1.368l-0.098,0.599c-0.192,1.187-0.503,2.352-0.924,3.461
		l-0.215,0.567l2.899,3.252c-0.289,0.552-0.602,1.092-0.936,1.616l-4.247-0.884l-0.384,0.469c-0.762,0.93-1.619,1.788-2.549,2.548
		l-0.469,0.384l0.884,4.248c-0.524,0.334-1.064,0.647-1.616,0.936l-3.252-2.899l-0.567,0.215c-1.111,0.421-2.276,0.732-3.462,0.924
		l-0.599,0.098l-1.367,4.135c-0.622,0.03-1.255,0.03-1.877,0l-1.368-4.135l-0.599-0.098c-1.187-0.192-2.352-0.503-3.461-0.924
		l-0.567-0.215l-3.252,2.899c-0.551-0.289-1.092-0.602-1.616-0.936l0.884-4.247l-0.469-0.384c-0.93-0.762-1.788-1.619-2.548-2.549
		l-0.384-0.469l-4.248,0.884c-0.334-0.524-0.647-1.064-0.936-1.616l2.899-3.252l-0.215-0.567C7.761,30.251,7.45,29.086,7.258,27.9
		L7.16,27.301l-4.135-1.367C3.009,25.623,3,25.311,3,24.996S3.009,24.369,3.024,24.058z"/>
	<path d="M25,38.996c7.72,0,14-6.28,14-14s-6.28-14-14-14s-14,6.28-14,14S17.28,38.996,25,38.996z M25,12.996
		c6.617,0,12,5.383,12,12s-5.383,12-12,12s-12-5.383-12-12S18.383,12.996,25,12.996z"/>
</g>
</svg>

    </template>
    
    <script>
    export default {
       name: 'Revokeword',
    }
    </script>
    
    <style scoped>
    </style>
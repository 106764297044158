<template>
    <!-- 遍历所有顶层任务 -->
    <div v-for="task in tasks" :key="task.ID" :style="{ paddingLeft: indent + 'px' }">
      <!-- 渲染任务 -->
      <div :id="'block_' + task.ID" class="tasktext_block">
        <a  :class="[task.tagsarray, 'tasktext']"
           :draggable="dragabledata"
           @dragstart="(event) => drag(task.ID, event)"  
           @click="() => edittask(task)"  
           :id="'tasktext_' + task.ID">
          {{ task.task || task.Name }}
        </a>
        <!-- 操作按钮区域 -->
        <div class="buttonzone_in_task">
          <Todayword class="todaytask" @click="() => schedule2today(task)"></Todayword>
          <Tomorrowword class="tomorrowtask" @click="() => schedule2tomorrow(task)"></Tomorrowword>
          <Giveupword class="giveuptask" @click="() => giveup(task)"></Giveupword>
          <Plusbutton class="giveuptask" @click="() => addchild(task)"></Plusbutton>
        </div>
      </div>
  
      <!-- 递归显示子任务 -->
      <RecursiveTaskTree
        v-if="task.children && task.children.length > 0"
        :tasks="task.children"
        :dragabledata="dragabledata"
        :indent="indent + 10"
        @drag="drag"
        @edittask="edittask"
        @schedule2today="schedule2today"
        @schedule2tomorrow="schedule2tomorrow"
        @giveup="giveup"
        @addchild="addchild"
      
      />
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
    import Plusbutton from  '../svg/plusbutton.vue'
  import Tomorrowword from '../svg/tomorrowword.vue';
  import Todayword from '../svg/todayword.vue';
  import Giveupword from '../svg/giveupword.vue';
  
  export default defineComponent({
    name: 'RecursiveTaskTree',
    components: {
      Todayword,
      Tomorrowword,
      Giveupword,
      Plusbutton
    },
    props: {
      tasks: {
        type: Array,
        required: true
      },
      dragabledata: {
        type: Boolean,
        default: true
      },
      indent: {
        type: Number,
        default: 0
      }
    },
    emits: ['edittask', 'schedule2today', 'schedule2tomorrow', 'giveup', 'drag',"addchild"], // 声明事件
    setup(props, { emit }) {
      // 任务相关操作
      const edittask = (task) => {
        emit('edittask', task);
      };
  
      const schedule2today = (task) => {
        emit('schedule2today', task);
      };
  
      const schedule2tomorrow = (task) => {
        emit('schedule2tomorrow', task);
      };
  
      const giveup = (task) => {
        emit('giveup', task);
      };
  
      const addchild = (task) => {
        emit('addchild', task);
      };


      const drag = (id, event) => {
        console.log("❤️🧡drag 事件💛💚💙💜")
        
        emit('drag', id, event);
      };
  
      return {
        addchild,
        edittask,
        schedule2today,
        schedule2tomorrow,
        giveup,
        drag
      };
    }
  });
  </script>
  
  <style scoped lang="scss">
  .tasktext_block {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }
  
.tasktext_block{
   display:flex;
    max-width: 100%;
    margin-right: 100px;
    overflow: auto;
    border-radius: 3px;
    border: 1px solid #b6b3b3;
    width: 40vw;
    
    margin:1px;
    justify-content: space-between;
    &:hover{
        background:rgb(247, 243, 243);
      }

    .tasktext{
    /* color:black; */
    color:#333;
    /* font-size: 13px; */
    max-width: 37vw;
    margin:1px;
    padding:0.5px;
    
       line-height: 1.75;

      

}

.hardtag{

background: rgb(206, 16, 41);
color: white;
font-size:larger;
font-family: goodfont;

}



}
  </style>
  
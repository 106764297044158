<template>
  <!-- <?xml version="1.0" encoding="iso-8859-1"?> -->
  <!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <!-- <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"> -->
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="2px"
    y="2px"
    width="22px"
    height="22px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
  <title>管理你的目标</title>
    <g
      id="B_-_102_x2C__target_x2C__Aim_x2C__archive_x2C__business_x2C__goal_x2C__mission_x2C__success"
    >
      <g>
        <g>
          <path
            d="M246.094,417.716c-99.123,0-179.765-80.646-179.765-179.774S146.971,58.168,246.094,58.168
				s179.765,80.646,179.765,179.773S345.217,417.716,246.094,417.716z M246.094,65.362c-95.156,0-172.572,77.419-172.572,172.58
				c0,95.162,77.416,172.581,172.572,172.581s172.571-77.419,172.571-172.581C418.665,142.781,341.25,65.362,246.094,65.362z"
          />
        </g>
        <g>
          <path
            d="M246.094,365.63c-70.379,0-127.637-57.28-127.637-127.688c0-70.367,57.258-127.615,127.637-127.615
				c70.395,0,127.665,57.248,127.665,127.615C373.759,308.35,316.488,365.63,246.094,365.63z M246.094,117.52
				c-66.413,0-120.443,54.021-120.443,120.422c0,66.441,54.031,120.495,120.443,120.495c66.429,0,120.472-54.054,120.472-120.495
				C366.565,171.541,312.522,117.52,246.094,117.52z"
          />
        </g>
        <g>
          <path
            d="M246.094,320.353c-45.437,0-82.402-36.97-82.402-82.411c0-45.441,36.965-82.411,82.402-82.411
				c45.438,0,82.403,36.969,82.403,82.411C328.497,283.383,291.531,320.353,246.094,320.353z M246.094,162.725
				c-41.47,0-75.209,33.742-75.209,75.217s33.739,75.217,75.209,75.217c41.471,0,75.21-33.742,75.21-75.217
				S287.564,162.725,246.094,162.725z"
          />
        </g>
        <g>
          <path
            d="M246.094,277.093c-21.591,0-39.157-17.563-39.157-39.151c0-21.592,17.566-39.158,39.157-39.158
				c21.592,0,39.158,17.566,39.158,39.158C285.252,259.53,267.686,277.093,246.094,277.093z M246.094,205.978
				c-17.625,0-31.964,14.339-31.964,31.964c0,17.622,14.339,31.958,31.964,31.958s31.965-14.336,31.965-31.958
				C278.059,220.317,263.719,205.978,246.094,205.978z"
          />
        </g>
        <g>
          <path
            d="M367.797,475.22c-0.272,0-0.547-0.031-0.82-0.095l-35.24-8.254c-0.928-0.218-1.732-0.794-2.235-1.604l-35.269-56.782
				c-0.59-0.95-0.704-2.121-0.31-3.167c0.396-1.047,1.255-1.851,2.325-2.174c8.383-2.53,16.602-5.732,24.429-9.518
				c1.665-0.807,3.673-0.215,4.637,1.367l45.377,74.467c0.454,0.602,0.724,1.351,0.724,2.163c0,1.979-1.599,3.585-3.575,3.597
				C367.824,475.22,367.811,475.22,367.797,475.22z M334.826,460.207l25.432,5.956l-39.405-64.667
				c-5.86,2.697-11.911,5.082-18.068,7.123L334.826,460.207z"
          />
        </g>
        <g>
          <path
            d="M122.083,475.22c-1.137,0-2.227-0.54-2.913-1.487c-0.85-1.174-0.913-2.743-0.158-3.981l45.556-74.759
				c0.963-1.581,2.97-2.173,4.637-1.366c7.853,3.798,16.088,6.999,24.479,9.516c1.073,0.322,1.935,1.126,2.331,2.174
				s0.281,2.221-0.311,3.172l-35.328,56.782c-0.503,0.809-1.306,1.385-2.233,1.602l-35.24,8.254
				C122.63,475.188,122.355,475.22,122.083,475.22z M169.028,401.496l-39.406,64.667l25.433-5.957l32.092-51.581
				C180.977,406.59,174.909,404.203,169.028,401.496z"
          />
        </g>
        <g>
          <path
            d="M318.765,444.364H208.284c-1.986,0-3.597-1.61-3.597-3.597s1.61-3.597,3.597-3.597h110.481
				c1.986,0,3.597,1.61,3.597,3.597S320.751,444.364,318.765,444.364z"
          />
        </g>
        <g>
          <path
            d="M94.295,241.539c-1.986,0-3.597-1.61-3.597-3.597c0-85.686,69.71-155.396,155.396-155.396
				c1.986,0,3.597,1.61,3.597,3.597s-1.61,3.597-3.597,3.597c-81.719,0-148.202,66.483-148.202,148.202
				C97.892,239.928,96.281,241.539,94.295,241.539z"
          />
        </g>
        <g>
          <path
            d="M394.882,92.742c-0.489,0-0.983-0.1-1.45-0.306c-1.399-0.617-2.255-2.051-2.134-3.576l2.161-27.219
				c0.063-0.811,0.401-1.576,0.957-2.171l20.134-21.55c1.044-1.117,2.681-1.453,4.079-0.835c1.399,0.617,2.255,2.051,2.134,3.576
				l-2.162,27.219c-0.063,0.811-0.401,1.576-0.957,2.171l-20.133,21.55C396.815,92.345,395.857,92.742,394.882,92.742z
				M400.53,63.463l-1.247,15.703l12.246-13.108l1.248-15.703L400.53,63.463z"
          />
        </g>
        <g>
          <path
            d="M394.882,92.742c-1.323,0-2.558-0.731-3.185-1.927c-0.71-1.354-0.486-3.008,0.558-4.125l20.133-21.55
				c0.557-0.596,1.299-0.985,2.106-1.103l27.059-3.967c1.514-0.224,2.999,0.537,3.708,1.89c0.709,1.354,0.484,3.008-0.559,4.125
				l-20.134,21.542c-0.557,0.595-1.299,0.984-2.105,1.103l-27.058,3.974C395.23,92.729,395.055,92.742,394.882,92.742z
				M416.784,70.971l-12.26,13.123l15.647-2.298l12.261-13.118L416.784,70.971z"
          />
        </g>
        <g>
          <path
            d="M246.094,241.539c-0.92,0-1.841-0.351-2.543-1.053c-1.405-1.405-1.405-3.682,0-5.086L392.34,86.602
				c1.404-1.405,3.682-1.405,5.086,0c1.405,1.405,1.405,3.682,0,5.086L248.637,240.485
				C247.935,241.188,247.014,241.539,246.094,241.539z"
          />
        </g>
      </g>
    </g>
    <g id="Layer_1"></g>
  </svg>
</template>
    <script>
export default {
  name: "goalicon",
};
</script>
    
    <style scoped>
</style>
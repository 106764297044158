// websocket.js
class WebSocketService {
    static instance = null;
    ws = null;
    callbacks = {};
    heartbeatInterval = 17000; // 17 seconds
    heartbeatTimer = null;
  
    static getInstance() {
      if (!WebSocketService.instance) {
        WebSocketService.instance = new WebSocketService();
      }
      return WebSocketService.instance;
    }
  
    connect(wsUrl) {
      if (this.ws) {
        console.log("WebSocket already connected.");
        return;
      }
  
      this.ws = new WebSocket(wsUrl);
  
      this.ws.onopen = () => {
        console.log("WebSocket connection opened.");
        this.startHeartbeat();
      };
  
      this.ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("Received via WebSocket:", message);
        
        // Handle server heartbeat ping
        if (message.type === 'ping') {
          this.sendMessage({ type: 'pong' }); // Send heartbeat response
        } else {
          this.handleCallbacks(message);
        }
      };
  
      this.ws.onerror = (error) => {
        console.log("WebSocket error:", error);
      };
  
      this.ws.onclose = () => {
        console.log("WebSocket connection closed.");
        this.stopHeartbeat();
        this.ws = null;
      };
    }
  
    startHeartbeat() {
      this.heartbeatTimer = setInterval(() => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
          this.sendMessage({ type: 'ping' }); // 发送心跳消息
        }
      }, this.heartbeatInterval);
    }
  
    stopHeartbeat() {
      if (this.heartbeatTimer) {
        clearInterval(this.heartbeatTimer);
        this.heartbeatTimer = null;
      }
    }
  
    handleCallbacks(message) {
      const type = message.type;
      if (this.callbacks[type]) {
        this.callbacks[type](message);
      }
    }
  
    registerCallback(type, callback) {
      this.callbacks[type] = callback;
    }
  
    sendMessage(message) {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify(message));
      } else {
        console.log("WebSocket is not open.");
      }
    }
  
    close() {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }
    }
  }
  
  export default WebSocketService.getInstance();

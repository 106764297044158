<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height"  viewBox="0 0 300 300">
  <!-- Circle background -->
  <circle cx="150" cy="150" r="100" fill="#000" stroke="blue" stroke-width="4"/>

  <!-- Logo (Emoji) -->
  <text x="150" y="185" text-anchor="middle" font-size="140" fill="#fff">🐤</text>

  <!-- Text along the circular path -->
  <defs>
    <path id="circlePath" d="M 150, 150 m -120, 0 a 120,120 0 0,1 240,0 a 120,120 0 0,1 -240,0"/>
  </defs>
  <text font-size="24" font-weight="bold" fill="red">
    <textPath href="#circlePath" text-anchor="middle" startOffset="50%">
      niubility 
    </textPath>
  </text>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="#F9D535" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="#DFB828" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐮
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="blue" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="red" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐠
    </text>
  </g>
</svg>


<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="orange" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="orange" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐶
    </text>
  </g>
</svg>



<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="pink" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="red" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐯
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="cayan" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="cayan" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐼
    </text>
  </g>
</svg>

<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="cayan" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="cayan" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🦕
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="cayan" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="cayan" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐬
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="purple" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="purple" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐌
    </text>
  </g>
</svg>

<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="purple" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="purple" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🦅
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="purple" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="purple" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐧
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="blue" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="blue" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐷
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="blue" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="blue" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🦩 
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="#a5cbf3" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="#a5cbf3" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🦁
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="#a5cbf3" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="#a5cbf3" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐘
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="#9fdd0d" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="#9fdd0d" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🦋
    </text>
  </g>
</svg>
<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="#dd2fd6" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="#dd2fd6" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐑 
    </text>
  </g>
</svg>

<svg class="badge" xmlns="http://www.w3.org/2000/svg" :height="height"  :width="width" viewBox="-40 -40 440 440">
  <!-- 外圈 -->
  <circle class="outer" fill="#113452" stroke="#fff" stroke-width="8" stroke-linecap="round" cx="180" cy="180" r="157"/>
  <!-- 内圈 -->
  <circle class="inner" fill="#113452" stroke="#fff" stroke-width="8" cx="180" cy="180" r="108.3"/>

  <!-- 定义文本路径，沿着外圈圆弧 -->
  <path id="text-path" d="M 80,180 a 100,100 0 1,1 200,0" fill="none" />

  <!-- 文本放置在顶部 -->
  <text font-size="24" fill="red" text-anchor="middle">
    <textPath href="#text-path" startOffset="50%">
      niubility
    </textPath>
  </text>

  <!-- 其他内容 -->
  <path class="inline" d="M89.4 276.7c-26-24.2-42.2-58.8-42.2-97.1 0-22.6 5.6-43.8 15.5-62.4m234.7.1c9.9 18.6 15.4 39.7 15.4 62.2 0 38.3-16.2 72.8-42.1 97" stroke="#CAA61F" stroke-width="7" stroke-linecap="round" fill="none"/>
  <g class="cow" >
    <text x="180" y="200" font-size="80" text-anchor="middle" dominant-baseline="central">
      🐿️
    </text>
  </g>
</svg>
     </template>
   <script>
   
export default {
  name: "niubadge",
  props: {
    height: {
      type: String,
      default: "200px"
    },
    width: {
      type: String,
      default: "200px"
    }
  }
};
     </script>
         
     <style scoped>
     </style>
<template>
    
  <div
    class="timer-container"
    @mousedown="startDrag"
    ref="timer"
  >
  <div class="closeiconzone">
    <closeicon class="closeicon" @click.stop="closeTimer"  />
   </div>
  <div v-if="uitype=='timer'">
  
    <!-- SVG Timer -->
 
    <div class="svg-timer" >
      <svg width="200" height="200">
        <circle
          cx="100"
          cy="100"
          r="90"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="offset"
          stroke-width="10"
          :stroke="currentColor"
          fill="none"
          class="progress-circle"
        />
      </svg>
      <div class="time-display">{{ minutes }}:{{ seconds }}</div>
    </div>
  

    <div class="controls">
      <button @click="startTimer">Start</button>
      <button @click="pauseTimer">Pause</button>
      <button @click="resetTimer">Reset</button>
      <button @click="closeTimer">Close</button>
      
    </div>
    </div>
    <Hourglass  :duration="totalTime"    :waterColor="getRandomDarkColor()" v-if="uitype=='glass'" />
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import emitter from '../eventbus';
import closeicon  from "../svg/close.vue"
import Hourglass from './timer/Hourglass.vue';
export default {
  props: {
    initialMinutes: {
      type: Number,
      default: 5 // 默认时间为5分钟
    },
    autoStart: {
      type: Boolean,
      default: false // 默认情况下不自动开始
    },
    timeruitype:{
      type:String,
      default:"glass",
    }
  },
  components:{
    closeicon,
    Hourglass 
  },
  setup(props) {
    const totalTime = ref(props.initialMinutes * 60);
    const remainingTime = ref(totalTime.value);
    const interval = ref(null);
    const uitype = ref(props.timeruitype)
    const circumference = 2 * Math.PI * 90;
    const offset = computed(
      () => circumference - (circumference * remainingTime.value) / totalTime.value
    );

    const minutes = computed(() =>
      Math.floor(remainingTime.value / 60).toString().padStart(2, '0')
    );
    const seconds = computed(() =>
      (remainingTime.value % 60).toString().padStart(2, '0')
    );

  function   getRandomDarkColor() {
    const r = Math.floor(Math.random() * 150); // 红色分量 (0-150)
    const g = Math.floor(Math.random() * 150); // 绿色分量 (0-150)
    const b = Math.floor(Math.random() * 150); // 蓝色分量 (0-150)
    return `rgb(${r}, ${g}, ${b})`;
  }
    const startTimer = () => {
      if (interval.value) return;

      interval.value = setInterval(() => {
        if (remainingTime.value > 0) {
          remainingTime.value--;
          var mode1 = false // 如果打开后每一跳都颜色变化容易分心
          if(mode1){
            updateStrokeColor();
          }
       
        } else {
          clearInterval(interval.value);
          interval.value = null;
        }
      }, 1000);
    };
    const getRandomColor = () => {
      let color;
      do {
        const r = Math.floor(Math.random() * 200);
        const g = Math.floor(Math.random() * 200);
        const b = Math.floor(Math.random() * 200);
        color = `rgb(${r}, ${g}, ${b})`;
      } while (color === 'rgb(255, 255, 255)');
      return color;
    };
    const currentColor = ref(getRandomColor());
    const pauseTimer = () => {
      clearInterval(interval.value);
      interval.value = null;
    };

    const resetTimer = () => {
      clearInterval(interval.value);
      remainingTime.value = totalTime.value;
      interval.value = null;
      currentColor.value = getRandomColor();
    };

    const closeTimer = () => {
      emitter.emit('closetimer', 1);
    };

  

    const updateStrokeColor = () => {
      currentColor.value = getRandomColor();
    };

    const timer = ref(null);
    const startDrag = (event) => {
      const timerElement = timer.value;
      let shiftX = event.clientX - timerElement.getBoundingClientRect().left;
      let shiftY = event.clientY - timerElement.getBoundingClientRect().top;

      const onMouseMove = (event) => {
        timerElement.style.left = event.clientX - shiftX + 'px';
        timerElement.style.top = event.clientY - shiftY + 'px';
      };

      document.addEventListener('mousemove', onMouseMove);

      document.onmouseup = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.onmouseup = null;
      };
    };

    onMounted(() => {
      if (props.autoStart) startTimer();
    });

    onUnmounted(() => {
      clearInterval(interval.value);
    });

    return {
      minutes,
      seconds,
      startTimer,
      pauseTimer,
      resetTimer,
      closeTimer,
      circumference,
      offset,
      totalTime,
      currentColor,
      uitype,
      startDrag,
      getRandomDarkColor,
      timer
    };
  }
};
</script>

<style scoped>
.timer-container {
  text-align: center;
  /* padding: 20px; */
  font-family: Arial, sans-serif;
  position: absolute;
  border-radius: 10%;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  top: 100px;
  left: 100px;
  cursor: move;
  user-select: none;
  z-index: 9999;
  background-color: white;
  display: flex;
  justify-content: center;
}

.svg-timer {
  margin-bottom: 20px;
  position: relative;
}

.progress-circle {
  transition: stroke-dashoffset 1s linear;
}

.time-display {
  font-size: 24px;
  color: #3f51b5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.controls {
  margin-top: 20px;
}

.controls button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.controls button:hover {
  background-color: #303f9f;
}
 .closeiconzone{
  /* float: right; */
  cursor: pointer;
  position:absolute;
  max-width:2vw ;
  right: 3%;
  z-index:999;
}

</style>

<template>
    <div class="three-container" ref="threeContainer"></div>
  </template>
  
  <script>
  import * as THREE from "three";
  import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
  import gsap from "gsap"; // 引入GSAP库
  
  export default {
    name: "danceanimation",
    props: {
      onAnimationEnd: {
        type: Function,
        required: true, // 父组件传递动画结束的回调函数
      },
    },
    mounted() {
      this.initThree();
    },
    methods: {
      initThree() {
        const container = this.$refs.threeContainer;
  
        // 初始化场景、相机和渲染器
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
          75,
          container.clientWidth / container.clientHeight,
          0.1,
          1000
        );
        camera.position.set(0, 20, 180); // 设置相机位置
  
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(container.clientWidth, container.clientHeight);
        container.appendChild(renderer.domElement);
  
        // 添加光源
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);
  
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
        directionalLight.position.set(5, 10, 7);
        scene.add(directionalLight);
  
        let mixer = null; // 动画混合器
  
        // 加载模型和动画
        const loader = new GLTFLoader();
        loader.load(
          "/robotcarton.glb", // 模型路径
          (gltf) => {
            const model = gltf.scene;
            model.scale.set(2, 2, 2); // 设置模型大小
            model.position.set(0, 0, 0); // 放置在场景中心
            scene.add(model);
  
            // 初始化动画混合器
            mixer = new THREE.AnimationMixer(model);
            const action = mixer.clipAction(gltf.animations[0]); // 播放第一个动画
            action.play();
  
            // 使用GSAP控制动画
            const timeline = gsap.timeline({
              onComplete: () => {
                console.log("动画结束");
                if (this.onAnimationEnd) {
                  this.onAnimationEnd('dance'); // 通知父组件动画完成
                }
              },
            });
  
            // 通过GSAP动画控制模型的动作
            timeline.to(model.rotation, {
              duration: 10, // 动画持续时间
              y: Math.PI * 2, // 旋转一个完整的360度
              ease: "power2.inOut", // 动画缓动
            });
  
            // 动画完成后的回调
            action.clampWhenFinished = true; // 动画播放完成后停止在最后一帧
            action.loop = THREE.LoopOnce; // 仅播放一次
          },
          undefined,
          (error) => {
            console.error("Error loading model:", error);
          }
        );
  
        // 添加鼠标控制
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
  
        // 动画循环
        const clock = new THREE.Clock();
        const animate = () => {
          requestAnimationFrame(animate);
          if (mixer) mixer.update(clock.getDelta()); // 更新动画混合器
          controls.update(); // 更新相机控制
          renderer.render(scene, camera);
        };
        animate();
  
        // 自动调整窗口大小
        window.addEventListener("resize", () => {
          camera.aspect = container.clientWidth / container.clientHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(container.clientWidth, container.clientHeight);
        });
      },
    },
  };
  </script>
  
  <style>
  .three-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent;
  }
  </style>
  
<template>
<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="12px" height="12px" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
viewBox="0 0 292 295"
 xmlns:xlink="http://www.w3.org/1999/xlink">
 <title>折叠</title>
 <defs>
 
 </defs>
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_576941424">
   <path class="fil0" d="M150 0c-4,0 -9,0 -13,1 -8,0 -16,2 -24,4 -14,4 -28,10 -40,17 -12,6 -23,15 -32,24 -4,5 -9,10 -13,16 -4,5 -7,11 -10,17 -8,14 -13,31 -15,48 -2,10 -3,21 -3,31 0,16 3,31 8,44 3,7 6,13 9,19 3,6 7,11 12,17 8,10 18,20 30,29 11,8 24,14 37,19 13,5 27,8 41,9 2,0 5,0 7,0 12,0 23,-2 34,-5 12,-4 25,-10 38,-19 13,-9 24,-19 34,-30 10,-12 19,-25 25,-38 7,-14 12,-28 15,-43 1,-7 2,-14 2,-22 0,-7 0,-14 -1,-22 -5,-30 -21,-58 -45,-79 -12,-11 -26,-19 -40,-25 -7,-4 -15,-6 -23,-8 -7,-2 -15,-3 -23,-4 -3,0 -6,0 -10,0z"/>
   <path class="fil1" d="M151 8c-5,0 -9,0 -14,1 -7,1 -14,2 -22,4 -13,4 -26,9 -38,16 -11,6 -21,14 -30,23 -4,4 -8,9 -12,15 -4,5 -7,10 -10,16 -7,13 -12,29 -14,45 -2,10 -3,20 -3,29 1,15 3,30 8,42 2,7 5,12 8,18 3,5 7,11 12,16 7,10 17,19 28,27 11,8 22,14 35,19 13,4 26,7 39,8 2,0 4,0 7,0 11,0 21,-2 31,-5 12,-3 24,-9 37,-18 12,-8 22,-18 32,-28 9,-12 17,-24 24,-36 6,-13 11,-27 13,-40 2,-7 2,-14 3,-21 0,-7 -1,-14 -2,-21 -4,-28 -19,-55 -42,-75 -12,-10 -24,-18 -38,-24 -7,-3 -14,-5 -21,-7 -7,-2 -15,-3 -22,-3 -4,-1 -7,-1 -9,-1z"/>
   <path class="fil2" d="M150 16c-4,0 -9,1 -13,1 -6,1 -13,2 -21,4 -13,4 -25,8 -36,15 -10,6 -20,13 -28,22 -4,4 -8,8 -11,14 -4,4 -7,9 -10,15 -6,13 -11,27 -13,43 -2,9 -3,18 -2,27 0,14 2,28 7,40 2,6 5,11 8,16 3,5 6,10 10,16 8,9 17,18 27,25 10,7 21,13 33,18 12,4 24,6 37,7 2,0 4,0 6,0 10,0 20,-1 30,-4 11,-3 23,-9 34,-17 11,-8 22,-17 31,-27 9,-11 16,-22 22,-34 6,-12 11,-25 13,-37 1,-7 2,-14 2,-20 0,-7 0,-13 -1,-20 -4,-27 -18,-52 -40,-71 -11,-9 -23,-17 -36,-22 -6,-3 -13,-5 -20,-7 -7,-2 -14,-3 -21,-3 -3,0 -6,-1 -8,-1z"/>
   <path class="fil3" d="M96 28l0 0c-5,2 -11,5 -16,8 -10,6 -20,13 -28,22 -4,4 -8,8 -12,14 -3,4 -6,9 -9,15 -6,13 -11,27 -13,43 -2,9 -3,18 -3,27 1,14 3,28 8,40 2,6 5,11 8,17 3,5 6,10 10,15 8,9 17,18 27,25 10,7 21,13 33,18 12,4 24,6 37,7 2,0 4,0 6,0 10,0 20,-1 30,-4 11,-3 23,-9 35,-17 10,-7 19,-15 27,-25 -3,3 -6,5 -9,7 -6,4 -12,7 -18,10 -7,3 -14,5 -20,7 -7,2 -14,4 -21,4 -3,1 -7,1 -10,1 -3,0 -7,0 -10,-1 -7,0 -13,-2 -19,-4 -2,-1 -4,-2 -5,-2 -1,-1 -2,-1 -2,-1 -1,-1 -2,-1 -2,-1 -3,-2 -5,-3 -7,-6 -2,-1 -4,-3 -6,-5l-1 -1c-2,-2 -5,-5 -7,-7 -3,-2 -6,-4 -9,-6 -1,0 -1,0 -2,-1 -1,0 -2,0 -3,0 -1,0 -2,-1 -3,-1 -1,0 -1,0 -2,0 0,0 -1,1 -2,1 0,0 -1,0 -1,0 -1,1 -2,1 -3,2 -1,0 -2,0 -2,0 -1,1 -1,1 -2,1 0,0 0,0 -1,0l0 0 0 0c-1,0 -1,0 -2,0 -1,-1 -3,-2 -4,-3 -5,-3 -9,-8 -13,-14 -2,-4 -5,-8 -6,-13 -2,-5 -4,-10 -5,-16 -1,-5 -1,-11 -2,-17 0,-6 -1,-11 -1,-17 1,-12 2,-23 4,-34 0,-5 2,-10 3,-14 1,-5 2,-9 4,-13 2,-5 5,-11 8,-16 4,-5 7,-11 12,-16 8,-11 18,-20 29,-29l0 0z"/>
   <path class="fil3" d="M222 61c-1,1 -1,1 -1,2 0,1 0,1 1,2 2,1 4,3 5,6 1,0 1,0 1,1 1,0 1,1 2,1 0,0 1,0 1,0 1,-1 1,-1 2,-2 0,0 0,-1 -1,-1 -2,-3 -4,-6 -7,-9 0,0 -1,0 -2,0l0 0c0,0 -1,0 -1,0z"/>
   <path class="fil3" d="M234 64c-1,0 -1,0 -1,1 0,0 0,1 1,2 1,1 3,2 4,4 1,0 2,0 3,0 1,-1 1,-3 0,-3 -1,-2 -3,-3 -4,-4 -1,-1 -1,-1 -2,-1l0 0c0,0 -1,0 -1,1z"/>
   <path class="fil3" d="M245 74c0,1 0,2 0,2 0,1 0,1 0,2 1,0 1,1 1,1 1,2 3,4 4,6 0,1 2,1 3,0 1,0 2,-2 1,-3 -2,-2 -3,-5 -5,-7 -1,-1 -1,-1 -2,-1l0 0c-1,0 -1,0 -2,0z"/>
   <path class="fil3" d="M242 84c0,0 -1,1 -1,1 0,1 0,2 0,2 2,2 3,3 4,5 1,1 1,1 2,1 0,0 1,0 1,-1 1,0 1,-1 1,-1 0,-1 0,-1 -1,-2 0,0 0,-1 -1,-1l0 0c-1,-1 -1,-2 -2,-3 0,-1 -1,-1 -1,-1l-1 0c0,0 0,0 -1,0z"/>
   <path class="fil3" d="M254 101c0,1 -1,1 -1,1 0,1 0,2 0,2 1,1 1,2 2,3 0,1 0,1 0,2 0,0 1,1 1,1 1,0 1,0 2,0 0,0 1,0 1,-1 0,0 0,-1 0,-1 0,-2 -1,-4 -2,-6 0,-1 -1,-1 -2,-1 0,0 -1,0 -1,0z"/>
   <path class="fil3" d="M265 118c-2,1 -3,3 -3,4 1,2 2,4 2,6 0,2 2,3 4,3 2,-1 3,-3 2,-4 0,-2 -1,-4 -1,-6 -1,-1 -1,-2 -2,-2 0,-1 -1,-1 -2,-1 0,0 0,0 0,0z"/>
   <path class="fil3" d="M249 127c0,3 0,7 1,10 0,1 0,2 1,2 1,0 2,-1 2,-2 0,-3 0,-7 0,-10 0,-1 -1,-2 -2,-2l0 0c-1,0 -2,1 -2,2z"/>
   <path class="fil3" d="M263 136c-1,0 -1,1 -1,1 0,1 0,1 0,2 0,1 0,1 1,1 0,1 0,1 1,1 0,0 1,0 1,-1 0,0 1,0 1,-1 0,-1 0,-1 0,-2 -1,0 -1,-1 -1,-1 0,0 -1,-1 -1,-1l0 0c-1,0 -1,0 -1,1z"/>
   <path class="fil3" d="M262 160c0,1 -1,3 -1,5 0,1 1,2 2,2 2,0 3,-1 3,-2 0,-2 0,-3 0,-5 0,-1 -1,-2 -2,-3l0 0c-1,0 -2,1 -2,3z"/>
   <path class="fil3" d="M253 172c0,1 -1,1 -1,2 0,1 0,2 0,3 0,1 -1,2 -1,4 0,1 0,3 2,3 0,0 1,0 2,0 0,-1 0,-1 1,-1 0,-1 0,-1 0,-1 0,-3 1,-6 1,-8 0,-1 -1,-2 -2,-2l0 0c-1,0 -2,0 -2,0z"/>
   <path class="fil3" d="M164 33c-1,0 -1,0 -2,1 0,1 0,1 1,2 0,1 0,1 1,1 2,1 4,1 6,1 1,1 2,1 2,0 1,0 1,-1 1,-1 1,-1 0,-3 -1,-3 -2,-1 -4,-1 -7,-2 0,0 0,0 0,0 -1,0 -1,0 -1,1z"/>
   <path class="fil3" d="M173 23c0,0 -1,1 -1,2 0,0 0,1 0,1 1,1 1,1 2,2 2,1 5,2 8,3 1,0 3,0 3,-2 0,0 0,-1 0,-1 -1,-1 -1,-1 -2,-2 -2,-1 -5,-2 -8,-3l-1 0c0,0 -1,0 -1,0z"/>
   <path class="fil3" d="M195 36c0,0 -1,1 -1,1 -1,1 -1,2 -1,2 1,1 1,1 2,2 2,1 4,2 6,3 1,1 3,0 3,-1 1,-1 0,-2 -1,-3 -2,-1 -4,-3 -6,-4 0,0 -1,0 -1,0l-1 0z"/>
   <path class="fil3" d="M199 238c-2,1 -4,2 -6,3 -2,1 -4,2 -7,3 0,0 -1,1 0,2 0,0 1,1 2,0 4,-1 8,-4 12,-6 0,0 1,0 1,-1 0,0 0,0 0,-1 0,0 -1,0 -1,0l0 0 -1 0z"/>
   <path class="fil3" d="M216 220c-2,2 -5,3 -7,5 -1,0 -1,1 -1,1 0,1 0,1 0,2 1,0 1,1 2,1 1,0 1,0 2,-1 2,-1 4,-3 7,-4 1,-1 1,-3 0,-4 0,0 -1,0 -1,0l-1 0c0,0 -1,0 -1,0z"/>
   <path class="fil3" d="M216 232c-2,1 -4,3 -6,4 -1,1 -1,2 0,3 0,1 1,1 1,1 1,0 1,0 2,-1 2,-1 4,-2 6,-4 1,-1 1,-2 0,-3 0,0 -1,-1 -2,-1 0,0 0,0 -1,1z"/>
   <path class="fil3" d="M181 251c-2,0 -4,0 -5,1 -1,0 -2,1 -2,2 0,1 1,2 3,2 1,0 3,0 4,-1 2,0 2,-1 2,-2 0,-1 -1,-2 -2,-2l0 0z"/>
   <path class="fil3" d="M166 249c-2,0 -4,0 -6,1 -1,0 -2,1 -2,2 0,1 2,2 3,2 2,0 4,-1 6,-1 1,0 2,-1 1,-3 0,-1 -1,-1 -2,-1l0 0z"/>
   <path class="fil3" d="M240 208c-2,1 -3,2 -4,3 -1,1 -1,2 0,3 0,0 1,0 2,0 1,-1 2,-2 4,-3 0,-1 0,-2 0,-2 0,-1 -1,-1 -1,-1 -1,0 -1,0 -1,0z"/>
   <path class="fil3" d="M93 41c-3,2 -6,4 -8,7 0,0 -1,1 -1,1 0,1 0,1 1,2 0,0 1,1 1,1 1,0 1,0 1,0 1,-1 1,-1 1,-1l1 -1 -1 1c3,-3 5,-5 7,-6 1,-1 1,-2 1,-3 -1,-1 -1,-1 -2,-1l0 0c-1,0 -1,0 -1,0z"/>
   <path class="fil3" d="M111 34c-2,1 -4,3 -6,5 -1,0 -1,2 -1,3 1,0 3,1 3,0 2,-1 3,-2 4,-3 1,0 1,0 2,-1 1,0 2,-2 1,-3 0,0 -1,-1 -2,-1 0,0 0,0 -1,0z"/>
   <path class="fil3" d="M85 65l0 0 0 0 0 0zm0 -5c-3,2 -5,4 -7,7 -1,1 0,2 1,3 1,1 2,0 3,-1 1,-1 2,-2 3,-4l0 1 0 -1 0 0 0 0c1,0 2,-1 2,-2 1,0 1,-1 1,-1 0,-1 0,-1 0,-2 -1,0 -1,-1 -2,-1 -1,0 -1,1 -1,1z"/>
   <path class="fil1" d="M114 161c0,-1 0,-3 0,-4 0,1 0,3 0,4zm45 -55c0,0 -1,0 -2,0 -3,0 -5,1 -8,1 -3,1 -5,2 -8,2 -3,1 -6,2 -9,3 -2,1 -5,2 -8,4 -2,2 -5,4 -7,7 0,1 -1,3 -2,5 0,1 -1,3 -1,4 -1,3 -2,7 -2,10l0 1 -1 1c-1,3 -1,5 -2,7 0,2 -1,4 -1,6 -1,5 -1,9 -1,14 1,6 2,10 4,14 1,2 2,4 3,6 1,1 1,2 2,3 1,1 2,1 3,2 2,1 4,2 7,3 1,1 3,1 5,1 1,0 2,0 2,0 4,-1 6,-2 9,-3 2,-1 5,-3 7,-5 1,0 2,-1 3,-2 2,-1 3,-1 4,-2l1 -1c1,-1 2,-1 3,-2 1,-1 2,-2 4,-3 1,-2 3,-4 4,-6 1,-1 2,-3 3,-6 1,-1 1,-3 1,-4l1 -2c0,-2 1,-4 2,-6 1,-2 1,-3 2,-4 0,-1 1,-2 2,-3 1,-1 2,-3 3,-4 2,-2 5,-5 6,-8 2,-3 3,-6 3,-10 -1,-3 -1,-6 -3,-9 -1,-1 -2,-3 -3,-4 0,-1 -1,-2 -3,-3 -1,-1 -2,-1 -3,-2 -2,-1 -4,-2 -5,-2 -2,-1 -5,-2 -8,-2 -2,-1 -5,-1 -7,-1z"/>
   <polygon class="fil0" points="123,82 123,82 123,82 "/>
   <polygon class="fil0" points="136,94 136,95 136,95 "/>
   <path class="fil0" d="M135 91c-1,-1 -1,-2 -1,-2l0 0 0 0 0 0c-1,-2 -2,-3 -3,-5 -1,-1 -2,-2 -3,-3 -1,0 -1,-1 -1,-1 0,0 -1,0 -1,0l0 0 -1 0c0,0 -1,0 -1,0l0 1 0 0 0 0 -1 0 0 0 0 0 0 0c0,1 0,1 0,1l0 1c-1,0 0,1 0,1l0 0c0,1 0,1 0,1 0,1 0,1 0,1 0,1 1,1 1,2 1,0 1,1 1,1 1,2 3,3 4,4l0 0c1,0 1,1 2,1l0 0 0 0 0 0 0 0c0,1 1,1 2,2l0 0c1,0 2,1 3,1l0 0c0,-1 0,-2 0,-2 -1,-2 -1,-3 -1,-4z"/>
   <path class="fil0" d="M96 77c0,0 0,0 0,1 0,0 1,1 1,2l0 0 0 0c1,1 2,2 3,3 1,0 1,1 2,1 1,1 3,2 5,3l0 0 0 0c0,0 1,0 1,0l0 0c0,0 1,0 1,1l0 0c1,0 3,0 4,1 0,-1 -1,-2 -1,-3l0 0c-1,-2 -2,-4 -4,-6 0,-1 0,-1 -1,-2l0 0c-1,-2 -3,-3 -4,-5 -1,-1 -3,-2 -4,-3l-1 0 0 0 -1 1 0 0 -1 0 0 1c0,0 -1,0 -1,0 0,1 0,1 0,2 0,0 0,1 0,1 0,1 0,1 1,2z"/>
   <polygon class="fil0" points="95,76 95,75 95,75 "/>
   <polygon class="fil0" points="95,74 95,74 95,74 "/>
   <polygon class="fil0" points="98,71 98,70 98,70 "/>
   <path class="fil0" d="M113 109l0 0 0 0 0 0 0 0c-1,-1 -1,-1 -1,-2l0 0c-1,-1 -2,-2 -3,-3l0 0 0 0 0 0c-1,-1 -2,-2 -3,-3 -1,-1 -2,-2 -3,-3 0,0 0,0 0,0l-1 0c0,0 -1,-1 -1,-1l-1 0c-1,1 -1,1 -2,1l0 1c0,0 0,1 0,1 0,1 0,1 0,2l0 0c0,1 0,1 1,1 0,1 1,2 2,2 0,1 1,1 1,2 2,1 3,1 5,2l0 0 0 0 0 0 0 0 0 0 0 0c2,0 3,1 5,1l0 1 0 0 1 0c0,0 1,0 1,0l0 0c0,0 0,-1 0,-1 0,0 -1,-1 -1,-1z"/>
   <polygon class="fil0" points="109,104 109,104 109,104 "/>
   <polygon class="fil0" points="80,104 80,104 80,104 "/>
   <polygon class="fil0" points="89,112 89,112 89,112 "/>
   <polygon class="fil0" points="68,101 68,101 69,101 "/>
   <polygon class="fil0" points="71,109 71,109 71,109 "/>
   <polygon class="fil0" points="82,113 82,113 82,113 "/>
   <path class="fil0" d="M68 106l0 0 0 0c0,1 1,1 1,2 1,0 1,1 2,1 0,1 1,1 1,1 1,1 2,1 3,1 1,0 2,1 3,1 1,0 2,0 4,1l0 0 0 0 1 0c0,0 1,0 2,0l0 0c1,0 1,0 1,0l0 0c1,0 2,0 3,-1 0,0 0,0 1,0 -2,-1 -3,-2 -4,-3l0 0c0,0 -1,-1 -2,-2l0 0c-1,-1 -3,-2 -4,-3 -1,-1 -2,-2 -3,-2 -1,-1 -2,-1 -3,-1l0 0c-1,-1 -2,-1 -3,-1l0 0 0 0c0,0 -1,0 -1,0 0,0 -1,0 -1,0 0,0 0,1 -1,1l0 0 0 1c0,0 0,1 -1,1 0,0 0,1 1,1 0,1 0,1 0,2z"/>
   <polygon class="fil0" points="153,85 153,85 153,85 "/>
   <path class="fil0" d="M153 80c-1,0 -1,-1 -2,-2 0,-1 0,-1 -1,-2 0,0 -1,0 -1,0 0,0 -1,0 -1,0l0 0c-1,0 -2,0 -2,1 -1,0 -1,1 -2,2 0,1 0,2 0,3 0,1 0,2 1,3 0,2 1,5 2,7 1,1 2,3 3,4l0 0c0,0 0,1 0,1l1 0 0 1c0,-1 0,-1 0,-1l0 0 0 0 0 0 1 -1c0,0 0,-1 0,-1l0 0 0 0 0 0c0,-1 0,-1 0,-2l0 0 0 0 0 0 0 0c1,-1 1,-2 1,-2l0 0 0 0c0,-2 0,-4 0,-6 0,-1 0,-2 0,-2 0,-1 0,-2 0,-3z"/>
   <path class="fil0" d="M133 53l0 0c0,0 1,0 1,0 0,0 -1,0 -1,0z"/>
   <polygon class="fil0" points="136,67 136,67 136,67 "/>
   <polygon class="fil0" points="129,64 129,64 128,64 "/>
   <polygon class="fil0" points="136,61 136,61 136,61 "/>
   <path class="fil0" d="M136 61c0,-1 0,-2 0,-2 0,-1 -1,-1 -1,-2l0 0c0,0 0,0 0,-1l-1 0 0 -1 -1 -1c0,0 0,0 -1,0 0,0 0,0 -1,0l0 0c0,0 0,0 0,0l-1 0c0,0 -1,0 -1,0l0 1 0 0c-1,1 -1,1 -1,1l0 0c0,1 0,1 0,2 0,0 0,0 0,1l0 0c-1,0 -1,1 0,1 0,0 0,1 0,1 0,1 0,1 0,2 0,0 0,0 1,1 0,1 1,2 1,3l0 0 0 0c1,1 1,1 2,2l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0c1,1 2,2 3,3 1,-2 1,-4 1,-6 0,-1 0,-2 0,-3 0,-1 0,-2 0,-3z"/>
   <polygon class="fil0" points="132,54 132,54 132,54 "/>
   <polygon class="fil0" points="128,55 129,55 129,55 "/>
   <polygon class="fil0" points="84,136 84,136 84,136 "/>
   <path class="fil0" d="M88 129l0 0c0,0 -1,0 -1,0l0 0c-1,0 -1,0 -1,1 0,0 -1,0 -1,0l0 0 -1 0 0 0 0 0 0 1 -1 0 0 0 0 0 0 0 0 0 0 1 0 0 0 1 0 0 0 1 0 0 0 0 0 1 1 1 0 0 0 0c0,0 0,1 1,1l0 0 0 0 0 0 0 0 0 0c1,1 1,1 1,1 1,0 1,0 1,0 1,1 1,1 2,1 0,0 0,0 1,0 1,0 1,0 2,0 1,0 1,0 2,0 3,-1 6,-2 8,-3l0 0c1,0 2,0 2,-1 0,0 -1,-1 -2,-1l0 0c0,0 -1,0 -1,-1 -1,0 -2,-1 -3,-1l0 0c0,0 0,0 -1,-1l0 0c0,0 -1,0 -2,-1 -2,0 -4,-1 -5,-1l0 0c-1,0 -1,0 -2,0z"/>
   <polygon class="fil0" points="94,139 94,139 94,139 "/>
   <polygon class="fil0" points="84,136 84,136 84,136 "/>
   <polygon class="fil0" points="83,135 83,135 83,135 "/>
   <polygon class="fil0" points="83,133 83,133 83,133 "/>
   <polygon class="fil0" points="83,131 83,131 84,131 "/>
   <path class="fil0" d="M170 86l0 0c0,0 0,1 -1,1 0,2 0,3 0,5 0,1 0,2 0,3l0 0 0 0 0 0 0 0 0 0c0,1 0,1 0,1l0 0c0,1 0,2 0,3l0 0 0 0c0,-1 1,-1 1,-1l0 -1 0 0 1 0 0 0 1 -1 0 0 0 0c0,-1 1,-2 2,-2l0 0c1,-2 2,-3 2,-5 1,-1 2,-3 3,-4 0,-1 0,-1 0,-1 0,-1 0,-2 -1,-3 0,-1 -1,-2 -2,-2l0 0c-1,0 -2,1 -3,1 -1,1 -1,2 -2,4 0,0 -1,1 -1,2z"/>
   <polygon class="fil0" points="171,66 170,66 170,67 "/>
   <path class="fil0" d="M189 90c2,-1 3,-2 5,-3 1,-1 2,-2 3,-3 1,-1 3,-3 4,-5 0,-1 0,-2 0,-2 0,-1 1,-1 0,-2 0,-1 0,-2 -1,-3 0,0 -1,0 -2,0 -1,0 -2,1 -3,1 0,1 -1,1 -1,1 0,1 -1,2 -1,2l0 0 0 0 0 0 0 0 0 0 0 0 0 0c-1,1 -1,3 -2,4 0,1 -1,3 -1,4 0,2 -1,4 -1,6l0 0 0 0z"/>
   <path class="fil0" d="M206 104c1,-1 1,-1 1,-2 1,0 1,-1 1,-2 0,-1 0,-2 -1,-3 -1,0 -2,0 -2,0l0 0 -1 0c0,0 -1,0 -1,0l0 0c-1,1 -1,1 -2,1l0 0 1 0 -1 0 1 0 -1 0 0 0 0 0c0,0 0,1 -1,1l0 0 -1 0c0,1 -1,1 -1,2l-1 0 0 0c0,1 -1,1 -1,2l0 0c-1,0 -1,1 -1,1 0,0 -1,1 -1,1l0 0c0,1 -1,2 -1,2l0 0 -1 1c0,0 0,1 -1,1l0 0c0,1 0,2 -1,2l0 0c1,0 1,0 1,0l0 0 0 0 0 0 0 0 0 0 0 0c2,-1 5,-1 7,-2 1,-1 3,-1 5,-2 1,-1 2,-2 3,-3z"/>
   <polygon class="fil0" points="84,149 84,149 84,149 "/>
   <path class="fil0" d="M89 155l1 0c1,0 2,-1 3,-1 0,-1 1,-1 1,-1l0 0c1,-1 2,-1 3,-2 -1,0 -2,0 -3,0l0 0c-1,0 -2,0 -3,0 -1,-1 -1,-1 -2,-1l-1 0 0 0c-1,0 -2,1 -3,1l0 0c-3,0 -5,0 -7,1 -1,0 -1,0 -2,1 0,0 -1,0 -1,0l0 1 0 1 -1 0c1,1 1,1 1,1 0,1 0,1 0,1 0,1 1,1 1,1 0,0 0,0 1,1 0,0 0,0 1,0 0,0 1,0 1,0l1 0c1,0 3,-1 4,-1l0 0c2,-1 4,-2 5,-3z"/>
   <polygon class="fil0" points="77,159 77,159 76,159 "/>
   <polygon class="fil0" points="81,174 81,174 81,174 "/>
   <polygon class="fil0" points="68,177 68,177 68,177 "/>
   <path class="fil0" d="M81 174l0 0c-1,0 -1,1 -2,1l0 0c-1,0 -2,0 -4,0l0 0c0,0 -1,0 -1,0 -1,0 -2,0 -2,1 -1,0 -1,0 -2,0 0,0 -1,0 -2,1l0 0 0 0c-1,0 -2,0 -3,1l0 0c0,0 -1,1 -1,1l-1 1 0 0 0 1c0,0 0,0 0,1 1,0 1,0 1,1l0 0 0 0 0 0 0 0 0 0c0,0 0,0 1,1 0,0 0,0 0,0 1,0 1,0 1,0 1,0 1,0 2,0l0 0 0 0 0 0c0,0 1,0 2,0 2,-1 3,-2 5,-3 2,-1 4,-3 6,-5 1,-1 2,-1 2,-2l0 0c-1,0 -1,0 -2,0z"/>
   <polygon class="fil0" points="65,178 65,178 65,178 "/>
   <polygon class="fil0" points="75,181 75,181 75,181 "/>
   <polygon class="fil0" points="65,178 65,178 65,178 "/>
   <polygon class="fil0" points="93,195 93,195 93,195 "/>
   <polygon class="fil0" points="86,191 86,191 86,191 "/>
   <path class="fil0" d="M90 196c0,0 0,0 1,0l0 0c1,0 1,0 2,-1 0,0 1,0 1,0 0,0 1,-1 1,-1 2,-1 3,-2 4,-4 0,0 1,0 1,-1l0 0c1,-1 2,-2 2,-3l1 0 0 -1c0,0 -1,0 -2,0l0 0c-1,0 -2,0 -2,0l0 0c-1,0 -2,1 -3,1l0 0 0 0c0,0 -1,0 -1,0l0 0c-2,1 -5,2 -7,3 -1,1 -1,1 -2,2l0 1 0 0 0 1 0 0 0 0 1 1c0,0 1,1 1,1 1,0 1,0 1,0 0,0 1,1 1,1z"/>
   <polygon class="fil0" points="90,225 90,225 90,225 "/>
   <polygon class="fil0" points="90,225 90,225 90,225 "/>
   <polygon class="fil0" points="97,224 97,224 97,224 "/>
   <polygon class="fil0" points="93,226 93,226 93,226 "/>
   <polygon class="fil0" points="89,221 89,221 89,221 "/>
   <path class="fil0" d="M127 211l0 0c0,0 0,1 -1,1l0 0c0,0 -1,1 -1,1l0 0c-1,1 -1,1 -1,2l0 0 0 0 0 0 0 0 0 0 0 0 0 0c-1,0 -1,1 -1,1l0 1 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0 0 1 0c0,1 0,1 1,1 0,0 0,0 0,0l1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 -1 0 0 0 0 0 0 0 0 1 0c0,-1 0,-2 1,-3 0,-2 1,-5 1,-7l0 0 0 0c0,0 -1,0 -1,0 0,1 -1,1 -2,2z"/>
   <polygon class="fil0" points="129,209 129,209 129,209 "/>
   <polygon class="fil0" points="128,219 128,219 127,219 "/>
   <path class="fil0" d="M122 220l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0l0 0z"/>
   <polygon class="fil0" points="148,236 148,236 148,236 "/>
   <polygon class="fil0" points="146,238 146,238 146,238 "/>
   <path class="fil0" d="M146 222c-1,-2 -2,-4 -3,-6l0 0c0,-1 -1,-2 -1,-3l0 0c-1,2 -1,3 -1,4 0,1 0,1 -1,2l0 0c0,0 0,1 0,2 0,0 0,1 0,2 0,0 0,0 0,1l0 0c-1,1 -1,3 -1,4l0 0c0,2 0,4 0,6 0,1 0,2 1,2 0,1 0,1 0,1l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c1,1 1,1 1,1 0,0 1,0 1,0 0,0 1,1 1,1 0,0 1,0 1,0 1,-1 1,-1 2,-1l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,0 0,0 0,0 1,-1 1,-1 1,-1l0 0c0,0 0,-1 1,-1 0,-1 0,-1 0,-2l0 0c0,-2 0,-3 0,-4 -1,-3 -1,-5 -2,-8z"/>
   <path class="fil0" d="M160 212c0,-1 0,-2 -1,-3 0,-1 -1,-3 -3,-4 0,-1 0,-1 -1,-2l0 0c0,0 -1,-1 -1,-2l0 0 -1 0c0,0 0,1 0,1 0,1 0,1 1,2l0 0 0 0 0 1 0 0 0 0 0 0 0 0c0,0 0,1 0,1 0,1 0,2 0,2l0 1 0 0 0 0c0,0 0,1 0,1l0 0 0 0 0 0 0 0c0,1 0,1 1,2l0 1c0,0 0,0 0,0l0 0c0,1 0,1 0,2 1,0 1,0 1,1l1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 1 0 0 -1 0 0c1,0 1,0 1,0l0 0 0 0 1 -1c0,0 0,0 0,-1 0,0 0,0 0,-1 0,0 0,0 0,-1l0 0z"/>
   <polygon class="fil0" points="155,215 155,215 155,214 "/>
   <polygon class="fil0" points="160,213 160,213 160,213 "/>
   <polygon class="fil0" points="196,162 196,162 196,162 "/>
   <path class="fil0" d="M197 163c0,0 0,0 -1,-1l0 0c0,-1 -1,-1 -2,-2l0 0c-2,-1 -3,-2 -5,-3 0,0 -1,0 -2,0l0 0c0,-1 0,-1 -1,-1l0 0c0,0 -1,0 -1,0l0 0c0,0 -1,-1 -1,-1l0 1c0,0 0,1 0,1l0 0 1 0 0 0 0 1c0,1 1,2 2,3 0,0 0,0 0,1 1,0 1,1 1,1l1 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c1,1 2,2 3,3 0,0 1,0 1,1 1,0 1,0 1,0l1 0 0 -1 1 0c0,0 1,0 1,-1l0 0 0 -1 0 -1c0,0 0,0 0,-1z"/>
   <polygon class="fil0" points="196,162 196,162 196,162 "/>
   <polygon class="fil0" points="189,164 189,164 189,164 "/>
   <polygon class="fil0" points="196,169 196,169 196,169 196,169 "/>
   <polygon class="fil0" points="191,191 191,191 191,191 "/>
   <path class="fil0" d="M191 188l0 0 0 0 0 0 0 0 0 0c0,-1 0,-1 0,-1 -1,-1 -2,-2 -2,-2 -2,-1 -4,-2 -7,-3l0 0 0 0c-1,-1 -1,-1 -2,-1l0 0c-1,0 -2,0 -2,-1l0 1c0,0 1,1 1,1l0 0 0 0 0 1 0 0 0 0 1 1 0 -1 0 0 0 1c1,1 1,1 2,2l0 0c0,1 0,1 1,1 0,1 0,1 0,1l0 0c0,0 1,0 1,1l1 0c0,1 1,2 2,2 0,0 1,1 1,1 0,0 1,0 1,0l0 0 1 0 0 0 0 0 0 0 1 -1c0,0 0,-1 0,-1 0,0 1,0 1,-1 0,0 0,0 0,-1 -1,0 -1,0 -1,0z"/>
   <path class="fil0" d="M185 210c0,-1 -1,-2 -2,-3 -1,-2 -3,-4 -5,-5 -3,-2 -5,-3 -7,-4 -1,-1 -2,-1 -3,-2 0,0 0,1 0,1l0 1 0 0 0 0 0 0c1,0 1,1 2,2 0,1 0,2 1,2l0 0c0,1 0,1 1,2l0 0 0 0c0,1 1,2 1,3l0 0c2,2 3,4 4,6l1 0c1,1 2,2 3,3l0 0 0 0 0 0 0 0 0 0c0,0 1,1 1,1 1,0 1,0 1,0l0 0 1 0 0 -1 1 0c0,-1 0,-1 0,-1l0 0c1,-1 1,-1 1,-2 0,-1 0,-1 0,-2 0,0 -1,-1 -1,-1z"/>
   <polygon class="fil0" points="178,202 178,202 178,202 "/>
   <polygon class="fil0" points="185,216 185,216 185,216 "/>
   <polygon class="fil0" points="181,216 181,216 181,216 "/>
   <polygon class="fil0" points="221,188 221,188 221,188 "/>
   <path class="fil0" d="M220 186c0,0 0,-1 0,-1l0 0 0 0 0 0 0 0 0 0c-1,0 -1,-1 -2,-1l0 0c-1,-1 -2,-2 -4,-2l0 0 0 0c-2,-1 -5,-2 -8,-2 -1,0 -1,0 -2,0 1,1 1,1 2,2l0 0c0,0 0,1 1,1l0 0c1,1 1,2 2,3l1 0c0,1 0,1 0,1 1,1 3,2 4,3l0 0c1,1 1,1 2,1 1,1 1,1 1,1l1 0 1 0 0 -1c1,0 1,0 1,-1 0,0 0,0 0,-1 1,0 1,0 1,-1 0,0 0,-1 0,-1 0,0 0,0 -1,-1z"/>
   <polygon class="fil0" points="206,180 206,180 206,180 "/>
   <polygon class="fil0" points="216,192 216,191 216,191 "/>
   <polygon class="fil0" points="218,192 218,192 218,192 "/>
   <polygon class="fil0" points="219,145 219,145 219,145 "/>
   <polygon class="fil0" points="210,146 210,146 211,146 "/>
   <path class="fil0" d="M226 149l0 0 0 0c0,0 0,-1 -1,-1 0,0 0,-1 -1,-1l0 0c0,0 -1,-1 -2,-1 -1,0 -2,0 -3,-1 -1,0 -1,0 -2,0 -2,0 -4,1 -7,1 -1,0 -2,0 -3,1l0 0c1,0 2,1 3,2l0 0 1 0 0 0 0 0c1,1 1,1 1,1 1,0 1,0 2,1l0 0 0 0c2,1 4,2 6,3l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c1,0 2,0 3,0 0,1 0,1 1,1l0 0c0,0 1,0 1,0l0 0 1 -1 0 0 0 0 0 0 0 0 0 0 0 -1c0,0 1,0 1,0 0,-1 0,-2 -1,-2 0,-1 0,-1 0,-2z"/>
   <polygon class="fil0" points="226,151 226,151 227,151 "/>
   <polygon class="fil0" points="226,149 226,149 226,149 "/>
   <path class="fil0" d="M202 132l0 0 0 0 0 0 0 0 0 0zm2 1l1 0c1,1 2,1 4,1 0,0 1,0 1,0 0,0 1,0 1,0 1,0 1,0 1,0 1,0 1,-1 1,-1l0 0 1 0 0 0c1,-1 1,-2 1,-3 0,-1 0,-2 -1,-2 0,-1 -1,-1 -2,-2 -1,0 -1,0 -1,0 0,0 -1,0 -1,0 -1,0 -1,0 -1,0l-1 0 0 0c-1,1 -2,1 -3,1 -1,1 -2,1 -3,1l0 1 0 -1 0 1c-1,0 -3,1 -4,2 1,0 1,0 2,0l0 0c0,1 1,1 1,1l0 0c1,0 1,0 1,0l0 0 0 0 0 0 0 0 0 0 0 0 1 1 0 0c0,0 1,0 1,0z"/>
   <polygon class="fil0" points="211,134 211,134 211,134 "/>
   <polygon class="fil0" points="209,134 209,134 208,134 "/>
   <polygon class="fil0" points="56,143 56,143 56,143 "/>
   <polygon class="fil0" points="60,143 60,143 60,143 "/>
   <polygon class="fil0" points="58,137 58,137 58,137 "/>
   <polygon class="fil0" points="72,140 72,140 72,140 "/>
   <polygon class="fil0" points="56,137 56,137 56,137 56,137 "/>
   <path class="fil0" d="M234 110c0,-1 -1,-2 -2,-2 0,0 -1,-1 -1,-1l0 0 0 0 0 0 0 0 -1 0 0 0c-1,0 -1,0 -1,0 -1,0 -1,1 -2,1 0,0 -1,0 -2,0 -2,1 -4,3 -6,5 -1,0 -2,1 -3,2 1,0 2,0 2,0 1,1 1,1 2,1l0 0c1,0 2,0 3,0l0 0 0 0 0 0 0 0 0 0 0 0 0 0c2,0 4,0 6,0 1,0 1,0 2,0 0,0 0,-1 1,-1l0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 -1 0 0 0 0c1,-1 1,-2 1,-4z"/>
   <polygon class="fil0" points="183,187 183,187 183,187 "/>
   <path class="fil0" d="M103 208l0 0 0 1c-2,0 -3,1 -5,2 0,0 -1,1 -1,1l0 0c-1,0 -2,1 -2,2l0 0 0 0c-1,1 -2,2 -3,3l0 0 0 0 0 0 0 0 0 0 0 0 0 0c-1,1 -2,2 -3,4l0 0c0,0 0,1 -1,2l0 0c0,0 0,1 1,1l0 0 0 1 1 0 0 0c0,0 1,0 1,1 1,0 2,0 2,0l0 0 0 0 0 0 0 0 0 0 0 0c1,0 2,-1 2,-1 1,0 1,0 2,-1 0,0 1,0 1,0 1,-1 2,-3 3,-4 0,-1 1,-2 2,-3 1,-3 2,-6 3,-10l-3 1 0 0z"/>
   <path class="fil0" d="M53 140l0 0 0 0 0 0zm10 3c1,-1 2,-1 3,-1l0 0c2,0 3,-1 4,-1l0 0c1,-1 1,-1 2,-1 0,0 1,0 1,0 0,-1 -1,-1 -1,-1 -3,-1 -5,-1 -8,-2l0 0c-2,0 -3,0 -5,0 -1,0 -1,0 -1,0 -1,0 -2,0 -2,0l0 0 0 0 0 0 0 0 0 0 0 0c-1,0 -1,0 -1,0l0 0 0 0 -1 1 0 0 0 0c0,1 0,1 -1,1l0 0 0 0c0,1 0,1 0,1l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0,1 1,1 1,1l0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0 0 0 0 0 0 1 1 0 0 0 0c0,0 1,0 1,0 1,0 3,0 4,0 1,0 2,0 3,0l0 0 0 0z"/>
   <path class="fil0" d="M165 68l0 0 0 0 0 0zm3 2l0 0c1,-1 2,-2 2,-4 1,0 1,-1 1,-2 1,0 1,-1 1,-2 0,0 0,-1 0,-2l0 0c0,-2 0,-4 -2,-4l0 0c-1,0 -2,1 -3,1 0,1 -1,2 -1,2 0,1 -1,1 -1,2 0,0 0,1 0,1 0,1 0,1 0,2 0,1 0,2 0,3l0 0c0,0 0,0 0,1l0 0c0,0 0,1 0,1 0,1 0,1 0,1l0 1c0,0 0,1 0,1l0 0c0,0 0,1 0,1l0 0c1,-1 2,-2 3,-3l0 0z"/>
  </g>
 </g>
</svg>

    </template>
    
    <script>
    export default {
       name: 'cherryicon',
    }
    </script>
    
    <style scoped>
    
   
    .fil1 {fill:#FFF1B1}
    .fil0 {fill:#843429}
    .fil2 {fill:#86B943}
    .fil3 {fill:#7CAB3E}
    svg{

/* pointer-events: none; */

}

    </style>
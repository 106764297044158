<template>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="12px" width="12px">
    <title>展开</title>
    <defs></defs><g id="Fruits"><path class="cls-1" d="M18.934,45a8.709,8.709,0,0,1-6.66-2.947C7.963,37.5,6,30.305,6,25.541,6,19.2,7.954,15.469,12.534,13.053c2.941-1.551,5.419-1.061,7.815-.586A18.656,18.656,0,0,0,24,12.938a18.656,18.656,0,0,0,3.651-.471c2.4-.475,4.876-.964,7.815.586C40.046,15.469,42,19.2,42,25.541c0,4.764-1.963,11.962-6.274,16.51-3.432,3.618-7.051,3.056-9.7,2.646A14.253,14.253,0,0,0,24,44.473a14.253,14.253,0,0,0-2.03.224A19.575,19.575,0,0,1,18.934,45Z"/><path class="cls-2" d="M12,26.541a1,1,0,0,1-1-1c0-5.066,1.359-6.743,3.866-8.065A3.763,3.763,0,0,1,16.741,17a1,1,0,0,1,0,2,1.777,1.777,0,0,0-.942.245C14.36,20,13,20.721,13,25.541A1,1,0,0,1,12,26.541Z"/><path class="cls-3" d="M27.289,11.876a8.366,8.366,0,0,1-3.167-.627,1,1,0,0,1-.613-1.061A8.249,8.249,0,0,1,27.58,4.11h0a8.249,8.249,0,0,1,7.3-.486,1,1,0,0,1,.613,1.06,8.3,8.3,0,0,1-8.2,7.192Zm.791-6.9h0Z"/><path class="cls-4" d="M24,14.938a1,1,0,0,1-1-1,14.169,14.169,0,0,0-3.7-9.227A1,1,0,0,1,20.7,3.287,15.963,15.963,0,0,1,25,13.938,1,1,0,0,1,24,14.938Z"/></g></svg>

</template>
    
    <script>
    export default {
       name: 'cherryicon',
    }
    </script>
    
    <style scoped>
    
    .cls-3{fill:#00cf66;}.cls-4{fill:#c6512b;}
    .cls-1{fill:#fd3730;}.cls-2{fill:#ff6e6e;}
    svg{

/* pointer-events: none; */

}

    </style>
<template>
    <div class="alarm-clock">
      <div class="display">
        <span>{{ time }}</span>
        <span>{{ amPm }}</span>
      </div>
    
      <div v-if="isRinging" class="alarm-ring">
        <p>⏰ Alarm Ringing!</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        time: "",
        amPm: "",
        alarmTime: "",
        alarmSet: null,
        isRinging: false,
      };
    },
    mounted() {
      this.updateTime();
      setInterval(this.updateTime, 1000);
    },
    methods: {
      updateTime() {
        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();
        this.amPm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        this.time =
          String(hours).padStart(2, "0") +
          ":" +
          String(minutes).padStart(2, "0");
  
        // Check if the alarm should ring
        if (this.alarmSet && this.getFormattedTime(now) === this.alarmSet) {
          this.ringAlarm();
        }
      },
      setAlarm() {
        this.alarmSet = this.alarmTime;
        this.isRinging = false; // Reset the alarm state if it was ringing
      },
      clearAlarm() {
        this.alarmSet = null;
        this.isRinging = false;
      },
      ringAlarm() {
        this.isRinging = true;
        setTimeout(() => {
          this.isRinging = false;
        }, 60000); // Stop ringing after 1 minute
      },
      getFormattedTime(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return (
          String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0")
        );
      },
    },
  };
  </script>
  
  <style scoped>
  .alarm-clock {
    width: 100px;
    padding: 10px;
    background-color: #f0a400;
    border-radius: 15px;
    text-align: center;
  }
  
  .display {
    font-size: 1em;
    background-color: #3d2b1f;
    color: #00eaff;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-bottom: 15px;
  }
  
  .controls {
    margin-bottom: 10px;
  }
  
  .alarm-ring {
    color: red;
    font-size: 1.5em;
    font-weight: bold;
  }
  </style>
  
<template>
    <div class="area_editproject_right">
      <p class="title">
        编辑项目名称 <span class="project-name">{{ projectname_old}} </span>
      </p>
  
      <a-input
        class="project-input"
        v-model:value="projectname_input"
        placeholder="请输入新的项目名称"
      />
  
      <!-- 提示区域 -->
      <div class="edit-hint">
        {{ isValidName ? '😊 名称看起来不错！' : '😟 名称不能为空或无效或与原名相同，请输入新名称。' }}
      </div>
      <div class="devotedtimearea" title="滑动选择预期完成该任务需要多少时间(minute)"><a-slider  v-model:value="esdevotedtime_fromslider" :max=3000  /></div>
    
      <button
        @click="editProjectInfo"
        class="edit-project-button"
        :disabled="!isValidName"
      >
        保存修改
      </button>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, computed } from "vue";
  import axios from "axios";
  import configure_options from "../../configure";
  import emitter from "../../eventbus.js";
  
  export default defineComponent({
    name: "EditProjectName",
    props: {
      project: {
        type: Object,
        default: () => ({
          projectcode: "00000",
          projectname: "默认项目名称",
        }),
      },
    },
    setup(props) {
      // 初始值为传入的项目名称
      const  esdevotedtime_fromslider = ref(props.project.Esdevotedtime)
      const projectname_input = ref(props.project.Name);
      const projectname_old = ref(props.project.Name);
      const  esdevotedtime_fromslider_old = ref(props.project.Esdevotedtime)
      const goalcode = ref(props.project.Goalcode);
      // 校验输入的名称是否有效
      const isValidName = computed(() => {
      const trimmedName = projectname_input.value.trim();
      return trimmedName.length > 0 && (trimmedName !== projectname_old.value||esdevotedtime_fromslider_old.value!=esdevotedtime_fromslider.value);
    });
  
      // 编辑项目名称的函数
      function editProjectInfo() {
        const env = configure_options["env"];
        const serverhost = configure_options["app_setting"][env]["serverhost"];
  
        if (!isValidName.value) {
          alert("项目名称不能为空或无效！");
          return;
        }
  
        const updatedProject = {
          goalcode:props.project.Goalcode,
          projectcode:props.project.Projectcode,
          changefields:{
            projectname: projectname_input.value,
            esdevotedtime:esdevotedtime_fromslider.value,
          }
        };
  
        axios
          .post(`${serverhost}/v1/updateprojectfromeditmode`, updatedProject)
          .then((response) => {
            console.log("项目名称更新成功：", response.data);
            alert("项目名称已成功修改！");
            emitter.emit("refresh_goaltree", "editproject_component_update");
          })
          .catch((error) => {
            console.error(error);
            if (error.response && error.response.status === 802) {
              alert("该项目名称已被占用，请选择其他名称！");
            } else {
              alert("更新项目名称时发生错误，请稍后再试。");
            }
          });
      }
  
      return {
        projectname_input,
        projectname_old,
        isValidName,
        editProjectInfo,
        esdevotedtime_fromslider,
        goalcode
      };
    },
  });
  </script>
  
  <style scoped lang="scss">
  .area_editproject_right {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;
    color: #333;
  }
  
  .project-name {
    color: #1a73e8;
    font-weight: bold;
  }
  
  .project-input {
    width: 100%;
    margin-bottom: 15px;
    height: 35px;
  }
  
  .edit-hint {
    font-size: 0.9em;
    color: #666;
    margin-left: 1px;
  }
  
  .edit-project-button {
    background-color: #1a73e8;
    color: white;
    padding: 3px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  .edit-project-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .edit-project-button:hover:not(:disabled) {
    background-color: #1454b8;
  }
  </style>
  
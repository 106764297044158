<template>
<div id="countdowntimer">
 
  
    <div class="number">{{ timershowstring }}</div>
    <div class="text">seconds</div>
</div>
</template>

<script>
import emitter from "../eventbus.js"; 
    export default {

        data() {
            return {
                timerEnabled: true,
                timerCount: 300,
                timershowstring:""
            }
        },

        watch: {

            timerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.timerCount--;
                        this.timershowstring = this.timerCount.toString()
                        
                        if(this.timerCount==0){
                            this.timershowstring = "休息一下"
                            emitter.emit("timercountsignal","off")
                        }
                        // 尝试将对应的代码合成到

                    }, 1000);
                }
            },

            timerCount: {
                handler(value) {

                    if (value > 0 && this.timerEnabled) {
                        setTimeout(() => {
                            this.timerCount--;
                            this.timershowstring = this.timerCount.toString()
                        // if(this.timerCount<5){
                        //     this.timershowstring = "休息一下"
                            
                        // }
                        if(this.timerCount==0){
                            
                            emitter.emit("timercountsignal","off")
                        }
                        }, 1000);
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }

        },
        methods: {

            play() {
                this.timerEnabled = true;
            },

            pause() {
                this.timerEnabled = false;
            }

        }

    }

</script>

<style scoped>
#countdowntimer {
  
    position:absolute;
    display: inline-block;
    font-family: "Lato", sans-serif;
    /* background:#F0A202; */
    background-color:#EF2F3C;
    left:0%;
    bottom:0%;
    /* padding: 5px; */


   
    
}


.number{

    font-family: "Montserrat", sans-serif;
    color: #183059;
    font-size: 2em;
    text-align:center;
    font-weight: bold;
    

}
.text{
    
    color:white;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-size: 0.4em;
    letter-spacing: 5px;
    font-weight: bold;
    padding-bottom: 5%;
   

}

/* #back2taskmanage{
    position: absolute;
    left:10%;
} */

html{
    background:black;
}

</style>
<template>
    <div class="upload-container">
      <input type="file" @change="handleFileUpload" accept=".ttf,.otf,.woff,.woff2" /><span class="vip-label">VIP</span>
      <div v-if="uploading">上传中... {{ progress }}%</div>
      <div v-if="uploadError" class="error">上传失败: {{ errorMessage }}</div>
    </div>
  </template>
  
  <script>
  import COS from 'cos-js-sdk-v5';
  import axios from 'axios';
  import configure_options from '../configure'
  export default {
    data() {
      return {
        uploading: false,
        uploadError: false,
        progress: 0,
        errorMessage: '',
      };
    },
    methods: {
      async handleFileUpload(event) {
        const file = event.target.files[0];
        if (!file) return;
  
        this.uploading = true;
        this.uploadError = false;
  
        try {
            var env =  configure_options["env"]
       
       console.log(configure_options["app_setting"][env])
       var serverhost =configure_options["app_setting"][env]["serverhost"]
          // 获取COS授权Token
          const tokenResponse = await axios.get(serverhost +'/v1/costoken');
          const { credentials } = tokenResponse.data;
  
          if (!credentials) {
            throw new Error('无法获取授权Token');
          }
  
          // 初始化COS实例
          const cos = new COS({
            getAuthorization: (options, callback) => {
              callback({
                TmpSecretId: credentials.tmpSecretId,
                TmpSecretKey: credentials.tmpSecretKey,
                SecurityToken: credentials.sessionToken,
                StartTime: tokenResponse.data.startTime,
                ExpiredTime: tokenResponse.data.expiredTime,
              });
            },
          });
  
          // 上传文件到OSS
          const result = await cos.uploadFile({
            Bucket: 'userimages-1255367272',
            Region: 'ap-shanghai',
            Key: `${Date.now()}_${file.name}`,
            Body: file,
            SliceSize: 1024 * 1024 * 5,
            onProgress: (progressData) => {
              this.progress = Math.round(progressData.percent * 100);
            },
          });
  
          this.uploading = false;
          this.$emit('uploadSuccess', `https://${result.Location}`);
        // 
        const uploadedUrl = `https://${result.Location}`;

        // 将上传的字体链接发送到 Golang 服务端
        try {
        await axios.post('/v1/uploadfonturl', {
            url: uploadedUrl, // 发送字体URL
            fontname: file.name,  // 文件名
        }
        
    
    
    
    );
        console.log('字体链接已成功同步到服务端');
        } catch (error) {
        console.error('同步字体链接时出错:', error);
        }

        } catch (error) {
          this.uploading = false;
          this.uploadError = true;
          this.errorMessage = error.message || '上传失败';
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .error {
    color: red;
  }
  </style>
  
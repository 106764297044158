<template>
  <div class="three-container" ref="threeContainer"></div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { gsap } from "gsap"; // 安装 gsap：npm install gsap

export default {
  name: "BalloonAnimation",
  props: {
    onAnimationEnd: {
      type: Function,
      required: true, // 父组件传递动画结束的回调函数
    },
  },
  mounted() {
    this.initThree();
  },
  methods: {
    initThree() {
      const container = this.$refs.threeContainer;

      // 初始化场景、相机和渲染器
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        container.clientWidth / container.clientHeight,
        0.1,
        1000
      );
      camera.position.set(0, 2, 40); // 设置相机位置

      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(renderer.domElement);

      // 添加光源
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(5, 10, 7);
      scene.add(directionalLight);

      let model = null; // 保存加载的模型

      // 加载气球模型
      const loader = new GLTFLoader();
      loader.load(
        "/ballon.glb", // 模型路径
        (gltf) => {
          model = gltf.scene;
          model.scale.set(1, 1, 1); // 设置合理大小
          model.position.set(0, -20, 0); // 初始位置在下方
          scene.add(model);

          // 动画：让气球从下向上运动
          this.animateBalloon(model);
        },
        undefined,
        (error) => {
          console.error("Error loading model:", error);
        }
      );

      // 添加鼠标控制
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;

      // 动画循环
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();

      // 自动调整窗口大小
      window.addEventListener("resize", () => {
        camera.aspect = container.clientWidth / container.clientHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(container.clientWidth, container.clientHeight);
      });
    },
    animateBalloon(model) {
      // 使用 GSAP 动画模型从下方移动到上方
      gsap.to(model.position, {
        y: 30, // 移动到相机视野顶部
        duration: 5, // 动画持续 5 秒
        ease: "power1.inOut", // 平滑过渡效果
        onComplete: () => {
          // 动画完成后调用父组件传递的回调
          console.log("animation end")
          if (this.onAnimationEnd) {
            this.onAnimationEnd("baloon"); // 通知父组件
          }
        },
      });
    },
  },
};
</script>

<style>
.three-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}
</style>

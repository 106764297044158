<template>
    <div>
      <a-table :columns="columns" :dataSource="formattedRows" :pagination="false" />
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { Table } from 'ant-design-vue';
  
  export default {
    components: {
      'a-table': Table
    },
    props: {
      headers: {
        type: Array,
        required: true
      },
      rows: {
        type: Array,
        required: true
      }
    },
    setup(props) {
      // Dynamically create columns based on headers, each column with sorting enabled
      const columns = ref(
        props.headers.map(header => ({
          title: header,
          dataIndex: header,
          key: header,
          sorter: (a, b) => {
            if (typeof a[header] === 'number' && typeof b[header] === 'number') {
              return a[header] - b[header];
            }
            if (typeof a[header] === 'string' && typeof b[header] === 'string') {
              return a[header].localeCompare(b[header]);
            }
            return 0;
          },
        }))
      );
  
      // Map rows data to an array of objects based on headers
      const formattedRows = computed(() =>
        props.rows.map(row => {
          const rowObject = {};
          props.headers.forEach((header, index) => {
            rowObject[header] = row[index];
          });
          return rowObject;
        })
      );
  
      return {
        columns,
        formattedRows
      };
    }
  };
  </script>
  
  <style scoped>
  .ant-table {
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
  }
  
  .ant-table-thead > tr > th {
    background-color: #f2f2f2;
  }
  
  .ant-table-tbody > tr > td {
    border: 1px solid #ddd;
  }
  </style>
  
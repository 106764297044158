<template>
    <div class="three-container" ref="threeContainer"></div>
  </template>
  
  <script>
  import * as THREE from "three";
  import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
  import { gsap } from "gsap"; // 安装 gsap：npm install gsap
  
  export default {
    name: "BalloonAnimation",
    props: {
      onAnimationEnd: {
        type: Function,
        required: true, // 父组件传递动画结束的回调函数
      },
    },
    mounted() {
      this.initThree();
    },
    methods: {
      initThree() {
        const container = this.$refs.threeContainer;
  
        // 初始化场景、相机和渲染器
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
          75,
          container.clientWidth / container.clientHeight,
          0.1,
          1000
        );
        camera.position.set(0, 20, 180); // 设置相机位置
  
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(container.clientWidth, container.clientHeight);
        container.appendChild(renderer.domElement);
  
        // 添加光源
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);
  
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
        directionalLight.position.set(5, 10, 7);
        scene.add(directionalLight);
  
        let model = null; // 保存加载的模型
  
        // 加载气球模型
        const loader = new GLTFLoader();
        loader.load(
          "/donut1.glb", // 模型路径
          (gltf) => {
            model = gltf.scene;
            model.scale.set(0.5, 0.5, 0.5); // 设置合理大小
            model.position.set(0, 200, 0); // 初始位置在顶部
            scene.add(model);
  
            // 动画：让气球从顶部下落并翻转
            this.animateBalloon(model);
          },
          undefined,
          (error) => {
            console.error("Error loading model:", error);
          }
        );
  
        // 添加鼠标控制
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
  
        // 动画循环
        const animate = () => {
          requestAnimationFrame(animate);
          controls.update();
          renderer.render(scene, camera);
        };
        animate();
  
        // 自动调整窗口大小
        window.addEventListener("resize", () => {
          camera.aspect = container.clientWidth / container.clientHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(container.clientWidth, container.clientHeight);
        });
      },
      animateBalloon(model) {
  // 使用 GSAP 时间线实现合理的甜甜圈下落和反弹动画
  const timeline = gsap.timeline({
    onComplete: () => {
      console.log("Animation end");
      if (this.onAnimationEnd) {
        this.onAnimationEnd('donut'); // 通知父组件动画完成
      }
    },
  });

  // 自然下落阶段
  timeline.to(model.position, {
    y: -10, // 模型落到底部
    x: "+=3", // 横向轻微偏移
    duration: 3, // 动画持续时间
    ease: "power2.inOut", // 初始慢、逐渐加速
  });

  // 不规则旋转阶段（同时进行）
  timeline.to(
    model.rotation,
    {
      x: Math.PI * 4, // 沿 X 轴快速翻转两圈
      z: Math.PI / 3, // 沿 Z 轴轻微倾斜
      duration: 3, // 同步下落时间
      ease: "power1.inOut",
    },
    "<" // 与下落同时开始
  );

  // 第一次反弹
  timeline.to(model.position, {
    y: 5, // 反弹到一定高度
    duration: 1.5, // 反弹时间
    ease: "power1.out", // 弹性减弱
  });

  // 第二次反弹
  timeline.to(model.position, {
    y: 2, // 更小的反弹高度
    duration: 1, // 持续时间
    ease: "power1.out", // 更弱的弹性
  });

  // 停止前的轻微摆动
  timeline.to(model.rotation, {
    x: "+=0.2", // 模拟轻微摆动
    z: "+=0.1",
    duration: 1.2, // 停止前的摆动时间
    ease: "elastic.out(1, 0.3)", // 弹性缓动
  });

  // 最终静止
  timeline.to(model.position, {
    y: 0, // 最终停在地面
    duration: 0.5,
    ease: "power1.out", // 平滑静止
  });
}


    },
  };
  </script>
  
  <style>
  .three-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent;
  }
  </style>
  
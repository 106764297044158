<template>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" height="18px" width="20x"     viewBox="0 0 24 24"><title>logout</title><path d="M21.71,11.3,19.46,9.05A1,1,0,0,0,18,10.46l.54.54H16.42V6a4,4,0,0,0-4-4H6A4,4,0,0,0,2,6V18a4,4,0,0,0,4,4h6.42a4,4,0,0,0,4-4V13h2.18l-.54.54a1,1,0,1,0,1.41,1.41l2.23-2.23A1,1,0,0,0,21.71,11.3ZM14.42,18a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V6A2,2,0,0,1,6,4h6.42a2,2,0,0,1,2,2v5H10.16a1,1,0,0,0,0,2h4.26Z"/></svg>

   
     </template>
   <script>
     export default {
       name: "logouticon",
     };
     </script>
         
     <style scoped>
     </style>